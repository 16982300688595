@-webkit-keyframes go-left {
    from {
        left: 250px;
        opacity: 0.1;
    }
    to {
        left: 315px;
        opacity: 1;
    }
}
@-webkit-keyframes go-right {
    from {
        left: 315px;
        opacity: 1;
    }
    to {
        left: 250px;
        opacity: 0;
    }
}
@-webkit-keyframes go-left-pop-up {
    from {
        left: 530px;
        opacity: 0.1;
    }
    to {
        left: 580px;
        opacity: 1;
    }
}
@-webkit-keyframes go-right-pop-up {
    from {
        left: 580px;
        opacity: 1;
    }
    to {
        left: 530px;
        opacity: 0;
    }

}
@-webkit-keyframes go-up-pop-up {
    from {
        margin-top: -5px;
        opacity: 0.1;
    }
    to {
        margin-top: -32px;
        opacity: 1;
    }
}
@-webkit-keyframes go-down-pop-up {
    from {
        margin-top: -32px;
        opacity: 1;
    }
    to {
        margin-top: -5px;
        opacity: 0;
    }
}
@-webkit-keyframes go-up {
    from {
        top: -5px;
        opacity: 0.1;
    }
    to {
        top: -55px;
        opacity: 1;
    }
}
@-webkit-keyframes go-up-login {
    from {
        top: -5px;
        opacity: 0.1;
    }
    to {
        top: -102px;
        opacity: 1;
    }
}
@-webkit-keyframes go-down {
    from {
        top: -55px;
        opacity: 1;
    }
    to {
        top: -5px;
        opacity: 0;
    }
}
@-webkit-keyframes go-up-other {
    from {
        top: -5px;
        opacity: 0.1;
    }
    to {
        top: -43px;
        opacity: 1;
    }
}
@-webkit-keyframes go-down-other {
    from {
        top: -43px;
        opacity: 1;
    }
    to {
        top: -5px;
        opacity: 0;
    }
}
@-webkit-keyframes go-from-left {
    from {
        left: 10px;
        opacity: 0;
    }
    to {
        left: 60px;
        opacity: 1;
    }
}
@-webkit-keyframes go-to-left {
    from {
        left: 60px;
        opacity: 1;
    }
    to {
        left: 10px;
        opacity: 0;
    }
}

@-webkit-keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes close {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}


@-webkit-keyframes sk-cubemove {
    25% {
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    }
    50% {
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    }
    75% {
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    }
    100% {
        -webkit-transform: rotate(-360deg);
    }
}

@keyframes sk-cubemove {
    25% {
        transform: translateX(42px) rotate(-90deg) scale(0.5);
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    } 50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    } 50.1% {
        transform: translateX(42px) translateY(42px) rotate(-180deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    } 75% {
        transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    } 100% {
        transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
    }
}


@-webkit-keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0.0)
    }
    50% {
        -webkit-transform: scale(1.0)
    }
}
@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}
@keyframes down-list {
    from {
        height: 0;
    }
    to {
        height: 534px;
    }
}
@keyframes blur{
  from{
      text-shadow:0px 0px 10px #fff,
      0px 0px 10px #fff,
      0px 0px 25px #fff,
      0px 0px 25px #fff,
      0px 0px 25px #fff,
      0px 0px 25px #fff,
      0px 0px 25px #fff,
      0px 0px 25px #fff,
      0px 0px 50px #fff,
      0px 0px 50px #fff,
      0px 0px 50px #7B96B8,
      0px 0px 150px #7B96B8,
      0px 10px 100px #7B96B8,
      0px 10px 100px #7B96B8,
      0px 10px 100px #7B96B8,
      0px 10px 100px #7B96B8,
      0px -10px 100px #7B96B8,
      0px -10px 100px #7B96B8;}
}
