.pop-up-feedback {
    z-index: 1;
    @include pop-up(100%, 390px, calc(50% - 200px), calc(30% + 100px));
    margin: 0;
    left: calc(50% - 350px);
    max-width: 600px;
    input.ng-valid {
        @include input();
        width: 520px;
    }
    .valid-input {
        @include input();
        width: 520px;
    }
    .wrapper-for-input {
        position: relative;
        z-index: 2;
        .invalid-input {
            @include input-wrong();
            width: 520px;
            background-color: rgba(249, 0, 0, 0.16);
            &:hover {
                background-color: transparent;
                border-bottom-color: $border-input-wrong;
            }
            &:focus {
                background-color: transparent;
                border-bottom-color: $border-input-wrong;
            }
        }
        .valid-input {
            @include input();
            width: 520px;
        }
        .help-other-header {
            display: none;
        }
        .input-help-animation-pop-up-in {
            @include help-for-input(220px, 30px, 1);
            margin-top: 5px;
            animation: go-left-pop-up 0.5s normal;
            left: 580px;
            z-index: 5;
        }
        .input-help-animation-pop-up-out {
            @include help-for-input(220px, 30px, 0);
            margin-top: 5px;
            animation: go-right-pop-up 0.5s normal;
            left: 580px;
            z-index: 5;
        }
        .help-header-button {
            @include help-login-registration-button();
            left: 530px;
            display: block;
            margin-top: 13px;
            position: absolute;
            z-index: 4;
        }
    }
    .pop-up-feedback-title {
        margin: 60px 0 30px 0;
        color: $color-text-popup;
        font-size: 30px;
        font-weight: 500;
        line-height: 37px;
    }
    .pop-up-button {
        width: 250px;
        height: 60px;
        border: none;
        outline: none;
        cursor: pointer;
        margin-top: 35px;
        font-size: 24px;
        font-weight: 400;
        font-family: "Avenir Next";
        letter-spacing: 1px;
        color: $color-button-feedback-popup;
        background-color: $background-button-popup-feedback;
        border-radius: 30px;
    }
}
.pop-up-share {
    z-index: 1;
    @include pop-up(100%, 550px, calc(50% - 200px), calc(30% + 100px));
    margin: 0;
    left: calc(50% - 350px);
    max-width: 650px;
    .pop-up-rating-link-wrapper, .pop-up-rating-or {
        display: none;
    }
    .pop-up-share-title {
        margin: 43px 0 6px 0;
        padding-left: 50px;
        font-size: 30px;
        font-weight: 500;
        line-height: 37px;
        color: $color-text-popup;
    }
    .pop-up-share-title-under {
        margin: 0 0 20px 0;
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 1px;
        color: $color-title-under-popup;
    }
    .pop-up-share-title-for {
        margin: 37px 0 5px 0;
        font-size: 24px;
        font-weight: 500;
        line-height: 37px;
        color: $color-text-popup;
    }
    .pop-up-share-wrapper {
        margin-bottom: 37px;
        .pop-up-share-button {
            margin: 0 9px 0 0;
            cursor: pointer;
            display: inline-block;
            width: 61px;
            height: 61px;
            &.fb {
                background: url("./images/fb_for_share.svg") no-repeat center center;
                background-size: contain;
            }
            &.share {
                background: url("./images/share.svg") no-repeat center center;
                background-size: contain;
            }
            &:last-child {
                margin: 0;
            }
        }
    }
    .pop-up-share-copy {
        display: none;
    }
    .pop-up-share-copy-show {
        z-index: 1;
        @include pop-up-share-copy(220px, 29px);
        animation: show 1s normal;
     }
     .pop-up-share-copy-show-error {
         z-index: 1;
         @include pop-up-share-copy(220px, 29px);
         color: #F36D4F;
         animation: show 1s normal;
      }
     .pop-up-share-copy-hide {
         @include pop-up-share-copy(220px, 29px);
         animation: close 1s normal;
      }
      .pop-up-share-copy-hide-error {
          @include pop-up-share-copy(220px, 29px);
          color: #F36D4F;
          animation: close 1s normal;
       }
    .pop-up-share-line {
        @include image(282px, 1px);
        margin: 0 auto;
        background-color: $background-popup-line;
    }
    .pop-up-share-scan-wrapper {
        @include image(145px, 145px);
        margin: 0 auto;
        background-color: $background-popup-scan-wrapper;
        border-radius: 10px;
        .pop-up-share-scan-image {
            width: 120px;
            padding: 10px;
        }
    }
}
.pop-up-rating {
    z-index: 1;
    @include pop-up(100%, 260px, calc(50% - 200px), calc(30% + 100px));
    margin: 0;
    left: calc(50% - 350px);
    max-width: 650px;
    .pop-up-rating-title {
        margin: 45px 0 53px 45px;
        font-size: 30px;
        font-weight: 500;
        line-height: 37px;
        color: $color-text-popup;
    }
    .pop-up-rating-link-wrapper {
        display: inline-block;
        .pop-up-rating-link {
            display: inline-block;
            padding-bottom: 2px;
            color: $color-text-popup-link;
            font-size: 24px;
            font-weight: 600;
            text-decoration: none;
            border-bottom: 1px solid rgba(85, 149, 196, .3);
            margin-left: 5px;
        }
        .pop-up-rating-if {
            color: $color-text-popup-rating-description;
            font-size: 14px;
            font-weight: 500;
            font-style: italic;
            margin-top: 10px;
        }
        &.left {
            float: left;
            margin-left: 110px;
        }
        &.right {
            float: left;
            padding-top: 2px;
        }
    }
    .pop-up-rating-or {
        float: left;
        line-height: 34px;
        margin: 0 40px;
        color: $color-text-popup-rating-text;
        font-size: 24px;
        font-weight: 500;
    }
}
.pop-up-question {
    z-index: 1;
    @include pop-up(700px, 315px, calc(50% - 200px), calc(30% + 100px));
    margin: 0;
    left: calc(50% - 350px);
    max-width: 700px;
    width: 100%;
    .pop-up-question-title {
        margin: 67px auto 18px auto;
        color: #444;
        font-family: "Avenir Next";
        font-size: 30px;
        font-weight: 500;
        line-height: 37px;
    }
    .pop-up-question-description {
        margin: 0 104px 30px 106px;
        font-family: "Avenir Next";
        color: #8d8d8d;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
    }
    .pop-up-question-button-yes {
        position: relative;
        display: inline-block;
        width: 250px;
        height: 60px;
        color: #fefefe;
        font-family: "Avenir Next";
        font-size: 24px;
        font-weight: 600;
        background-color: #a6be66;
        border-radius: 30px;
        border: 0;
        outline: none;
        cursor: pointer;
    }
    .pop-up-question-button-no {
        position: relative;
        display: inline-block;
        width: 250px;
        height: 60px;
        color: #f36d4f;
        font-family: "Avenir Next";
        font-size: 24px;
        font-weight: 600;
        background-color: #FFFFFF;
        border-radius: 30px;
        border: 0;
        outline: none;
        cursor: pointer;
    }
}
