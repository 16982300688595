.registration {
    max-width: 100%;
    height: 100%;
    background: url('./images/bg.svg') no-repeat;
    background-size: cover;
    color: $color-text;
    text-align: center;
    font-family: $login-font-family;
    .login-social {
        margin-top: 35px;
        .image-button {
            @include image(49px, 49px);
            padding: 0 12px;
            cursor: pointer;
        }
    }
    .header-registration {
        @include footer-header(100%, 100px, transparent);
        a {
            text-decoration: none;
        }
        .header-registration-image {
            @include image(10px, 16px);
            float: left;
            margin-top: 52px;
            margin-left: 51px;
            font-family: $login-font-family;
            font-size: 18px;
            font-weight: 500;
            color: $color-text;
            line-height: 25px;
            background: url('./images/layer.png') no-repeat;
            .header-registration-image-title {
                @include image(55px, 16px);
                margin-left: 13px;
                margin-top: -4px;
            }
        }
        .header-registration-image-logo {
            display: inline-block;
            margin: 35px 55px 0 50px;
        }
    }
    main {
        height: 100%;
        padding: 100px 0 89px;
        margin: -100px 0 -89px;
        box-sizing: border-box;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        section {
            margin: auto;
            .wrapper {
                width: 373px;
                display: inline-block;
                border-radius: 10px;
                color: $color-text-wrapper;
                background-color: $color-text;
                .wrapper-inside {
                    height: 615px;
                    margin-top: -2px;
                    border-radius: 10px;
                    background: url('./images/center_registration.png') no-repeat;
                    box-shadow: 0 11px 107.4px 23.6px rgba(2,28,48,0.3);
                    .registration-trolleybus {
                        padding-top: 15px;
                        .trolley-img {
                            width: 100%
                        }
                        .button-close {
                            @include button-close-open-gif(-83px, 33px, hidden)
                        }
                    }
                    .registration-trolleybus:hover {
                        padding-top: 15px;
                        .button-close {
                            @include button-close-open-gif(-83px, 33px, visible)
                        }
                    }
                    .registration-input {
                        margin-top: -30px;
                        color: $color-text-input;
                        .wrapper-for-input {
                            position: relative;
                            z-index: 2;
                            .help-other-registration {
                                display: none;
                            }
                            .valid-input {
                                @include input();
                                width: 257px;
                                margin-bottom: 13px;
                            }
                            .invalid-input {
                                @include input-wrong();
                                width: 257px;
                                margin-bottom: 13px;
                            }
                            .input-help-animation-other-in {
                                @include help-for-input(220px, 30px, 1);
                                animation: go-left 0.5s normal;
                                z-index: 5;
                            }
                            .input-help-animation-other-out {
                                @include help-for-input(220px, 30px, 0);
                                animation: go-right 0.5s normal;
                                z-index: 5;
                            }
                            .input-help-animation-email-in {
                                @include help-for-input(220px, 45px, 1);
                                animation: go-left 0.5s normal;
                                z-index: 6;
                            }
                            .input-help-animation-email-out {
                                @include help-for-input(220px, 45px, 0);
                                animation: go-right 0.5s normal;
                                z-index: 6;
                            }
                            .help-registration-button {
                                @include help-login-registration-button();
                                top: 35px;
                                left: 280px;
                                position: absolute;
                                z-index: 4;
                            }
                        }
                        input.ng-valid-email,
                        input.ng-valid-parse,
                        input.ng-invalid,
                        input.ng-valid-val,
                        input.ng-valid {
                            @include input();
                            width: 257px;
                            margin-bottom: 13px;
                        }
                        .button-submit {
                            @include button-submit(193px, 43px, $background-button-registration-color);
                            margin-top: 20px;
                            font-size: 20px;
                            background-image: linear-gradient(to top, rgba(215, 215, 215, 0.22) 0%, rgba(255, 255, 255, 0.22) 100%);
                            border-radius: 7px;
                            box-shadow: 0 3px 0 rgba(29, 154, 177, 0.08);                    }
                    }
                    .registration-wrapper-for-help {
                        margin-left: 103px;
                        text-align: center;
                        cursor: default;
                        .registration-for-help {
                            @include image(163px, 38px);
                            padding-bottom: 21px;
                            font-family: $login-font-family-button;
                            font-size: 14px;
                            // More?
                            font-weight: 400;
                        }
                    }
                    .social-registration{
                        margin-top: -5px;
                        .image-button {
                            padding-left: 5px;
                            @include image(45px, 45px);
                            padding-right: 5px;
                            cursor: pointer;
                        }
                    }
                    .button-registration {
                        @include button-submit(60px, 25px, $background-button-login-color);
                        position: relative;
                        margin-top: 20px;
                    }
                    .wrapper-link-wrong-right {
                        position: relative;
                        .login-registration-link {
                            margin-top: 30px;
                            font-size: 16px;
                            font-weight: 500;
                            cursor: default;
                            a {
                                color: $color-login-registration-link;
                            }
                        }
                        .wrapper-registration-link-wrong {
                            @include wrapper-link-error(242px, 63px, -20px, 60px);
                            animation: go-from-left 0.5s normal;
                        }
                        .wrapper-registration-link-wrong-close {
                            @include wrapper-link-error(242px, 63px, -20px, 60px);
                            animation: go-to-left 0.5s normal;
                        }
                        .wrapper-server-registration-link-wrong {
                            @include wrapper-link-error(307px, 100px, -20px, 30px);
                            animation: go-from-left 0.5s normal;
                        }
                        .wrapper-server-registration-link-wrong-close {
                            @include wrapper-link-error(307px, 100px, -20px, 30px);
                            animation: go-to-left 0.5s normal;
                        }
                    }

                }
            }
            .wrapper-inside-post {
                height: 515px;
                margin-top: -2px;
                border-radius: 10px;
                background: url('./images/center_registration.png') no-repeat;
                .registration-trolleybus {
                    padding-top: 15px;
                    .trolley-img {
                        width: 100%
                    }
                    .button-close {
                        @include button-close-open-gif(-83px, 33px, hidden)
                    }
                }
                .registration-trolleybus:hover {
                    padding-top: 15px;
                    .button-close {
                        @include button-close-open-gif(-83px, 33px, visible)
                    }
                }
                .registration-trolleybus-close {
                    display: none;
                }
                .post-registration-input {
                    margin-top: 0;
                    padding-top: 30px;
                    color: $color-text-input;
                    .wrapper-for-input {
                        position: relative;
                        z-index: 2;
                        .help-other-post-registration {
                            display: none;
                        }
                        .valid-input {
                            @include input();
                        }
                        .invalid-input {
                            @include input-wrong();
                        }
                        .input-help-animation-other-in {
                            @include help-for-input(220px, 30px, 1);
                            animation: go-left 0.5s normal;
                            z-index: 5;
                        }
                        .input-help-animation-other-out {
                            @include help-for-input(220px, 30px, 0);
                            animation: go-right 0.5s normal;
                            z-index: 5;
                        }
                        .input-help-animation-email-in {
                            @include help-for-input(220px, 45px, 1);
                            animation: go-left 0.5s normal;
                            z-index: 5;
                        }
                        .input-help-animation-email-out {
                            @include help-for-input(220px, 45px, 0);
                            animation: go-right 0.5s normal;
                            z-index: 5;
                        }
                        .help-post-registration-button {
                            @include help-login-registration-button();
                            top: 35px;
                            left: 280px;
                            position: absolute;
                            z-index: 4;
                        }
                    }
                    input.ng-valid-email,
                    input.ng-valid-parse,
                    input.ng-invalid,
                    input.ng-valid-val,
                    input.ng-valid {
                        @include input();
                    }
                    .post-registration-button-submit {
                        @include button-submit(193px, 43px, $background-button-registration-color);
                        margin-top: 40px;
                        font-size: 20px;
                        background-image: linear-gradient(to top, rgba(215, 215, 215, 0.22) 0%, rgba(255, 255, 255, 0.22) 100%);
                        border-radius: 7px;
                        box-shadow: 0 3px 0 rgba(29, 154, 177, 0.08);
                    }
                    .login-registration-link-wrong {
                        display: inline-block;
                        margin: 3px 5px 5px 19px;
                        padding-top: 5px;
                        font-size: 17px;
                        font-weight: 400;
                        line-height: 23px;
                        text-align: left;
                    }
                }
            }
        }
    }
    .footer-registration {
        @include footer-header(100%, 89px, transparent);
        clear: both;
        padding: 20px 0;
        box-sizing: border-box;
        // position: absolute;
        bottom: -85px;
        // margin: 3px 0 0;
        // .footer-registration-image {
        //     padding-top: 28px;
        // }
        a {
            padding-left: 73px;
            margin: 0;
            opacity: 1;
            color: white;
            font-family: "Avenir Next";
            font-size: 13px;
            color: white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        div {
            margin-top: 10px;
            margin-left: 75px;
            .fb_link {
                background: url("./images/facebook_icon_light.png") no-repeat center center;
                background-size: contain;
                margin-left: 7px;
                border-radius: 3px;
                display: inline-block;
                width: 20px;
                height: 20px;
                cursor: pointer;
                margin-bottom: -4px;
            }
        }
    }
}

.registration-mobile {
    .login-social {
        margin-top: 35px;
        .image-button {
            @include image(49px, 49px);
            padding: 0 12px;
            cursor: pointer;
        }
    }
    display: none;
    max-width: 375px;
    height: 670px;
    background: url('./images/background.png') no-repeat;
    color: $color-text-wrapper;
    text-align: center;
    font-family: $login-font-family;
    .registration-trolleybus {
        padding-top: 15px;
        .trolley-img {
            width: 100%
        }
        .button-close {
            @include button-close-open-gif(-83px, 33px, hidden)
        }
    }
    .registration-trolleybus:hover {
        padding-top: 15px;
        .button-close {
            @include button-close-open-gif(-83px, 33px, visible)
        }
    }
    .registration-input {
        margin-top: 50px;
        color: $color-text-input;
        .wrapper-for-input {
            position: relative;
            z-index: 2;
            .help-other-registration {
                display: none;
            }
            .valid-input {
                @include input();
                width: 257px;
                margin-bottom: 13px;
            }
            .invalid-input {
                @include input-wrong();
                width: 257px;
                margin-bottom: 13px;
            }
            .input-help-animation-other-in {
                @include help-for-input(220px, 30px, 1);
                animation: go-left 0.5s normal;
                z-index: 5;
            }
            .input-help-animation-other-out {
                @include help-for-input(220px, 30px, 0);
                animation: go-right 0.5s normal;
                z-index: 5;
            }
            .input-help-animation-email-in {
                @include help-for-input(220px, 45px, 1);
                animation: go-left 0.5s normal;
                z-index: 6;
            }
            .input-help-animation-email-out {
                @include help-for-input(220px, 45px, 0);
                animation: go-right 0.5s normal;
                z-index: 6;
            }
            .help-registration-button {
                @include help-login-registration-button();
                top: 35px;
                left: 280px;
                position: absolute;
                z-index: 4;
            }
        }
        input.ng-valid-email,
        input.ng-valid-parse,
        input.ng-invalid,
        input.ng-valid-val,
        input.ng-valid {
            @include input();
            width: 257px;
            margin-bottom: 13px;
        }
        .button-submit {
            @include button-submit(193px, 43px, $background-button-registration-color);
            margin-top: 20px;
            font-size: 20px;
            background-image: linear-gradient(to top, rgba(215, 215, 215, 0.22) 0%, rgba(255, 255, 255, 0.22) 100%);
            border-radius: 7px;
            box-shadow: 0 3px 0 rgba(29, 154, 177, 0.08);                    }
    }
    .registration-wrapper-for-help {
        margin-left: 103px;
        text-align: center;
        cursor: default;
        .registration-for-help {
            @include image(163px, 38px);
            padding-bottom: 21px;
            font-family: $login-font-family-button;
            font-size: 14px;
            // More?
            font-weight: 400;
        }
    }
    .social-registration{
        margin-top: -5px;
        .image-button {
            padding-left: 5px;
            @include image(45px, 45px);
            padding-right: 5px;
            cursor: pointer;
        }
    }
    .button-registration {
        @include button-submit(60px, 25px, $background-button-login-color);
        position: relative;
        margin-top: 20px;
    }
    .wrapper-link-wrong-right {
        position: relative;
        .login-registration-link {
            margin-top: 37px;
            font-size: 16px;
            font-weight: 500;
            cursor: default;
            a {
                color: $color-login-registration-link;
            }
        }
        .wrapper-registration-link-wrong {
            @include wrapper-link-error(242px, 63px, -20px, 60px);
            animation: go-from-left 0.5s normal;
        }
        .wrapper-registration-link-wrong-close {
            @include wrapper-link-error(242px, 63px, -20px, 60px);
            animation: go-to-left 0.5s normal;
        }
        .wrapper-server-registration-link-wrong {
            @include wrapper-link-error(307px, 100px, -20px, 30px);
            animation: go-from-left 0.5s normal;
        }
        .wrapper-server-registration-link-wrong-close {
            @include wrapper-link-error(307px, 100px, -20px, 30px);
            animation: go-to-left 0.5s normal;
        }
    }
    .post-registration-input {
        margin-top: 0;
        padding-top: 30px;
        color: $color-text-input;
        .wrapper-for-input {
            position: relative;
            z-index: 2;
            .help-other-post-registration {
                display: none;
            }
            .valid-input {
                @include input();
            }
            .invalid-input {
                @include input-wrong();
            }
            .input-help-animation-other-in {
                @include help-for-input(220px, 30px, 1);
                animation: go-left 0.5s normal;
                z-index: 5;
            }
            .input-help-animation-other-out {
                @include help-for-input(220px, 30px, 0);
                animation: go-right 0.5s normal;
                z-index: 5;
            }
            .input-help-animation-email-in {
                @include help-for-input(220px, 45px, 1);
                animation: go-left 0.5s normal;
                z-index: 5;
            }
            .input-help-animation-email-out {
                @include help-for-input(220px, 45px, 0);
                animation: go-right 0.5s normal;
                z-index: 5;
            }
            .help-post-registration-button {
                @include help-login-registration-button();
                top: 35px;
                left: 280px;
                position: absolute;
                z-index: 4;
            }
        }
        input.ng-valid-email,
        input.ng-valid-parse,
        input.ng-invalid,
        input.ng-valid-val,
        input.ng-valid {
            @include input();
        }
        .post-registration-button-submit {
            @include button-submit(193px, 43px, $background-button-registration-color);
            margin-top: 40px;
            font-size: 20px;
            background-image: linear-gradient(to top, rgba(215, 215, 215, 0.22) 0%, rgba(255, 255, 255, 0.22) 100%);
            border-radius: 7px;
            box-shadow: 0 3px 0 rgba(29, 154, 177, 0.08);
        }
        .login-registration-link-wrong {
            display: inline-block;
            margin: 3px 5px 5px 19px;
            padding-top: 5px;
            font-size: 17px;
            font-weight: 400;
            line-height: 23px;
            text-align: left;
        }
    }
}


// @media  (max-width: 385px) {
//     .registration {
//         .header-registration {
//             height: 150px;
//             .header-registration-image-logo {
//                 margin-top: 50px;
//             }
//         }
//         main {
//             padding-top: 150px;
//             margin-top: -150px;
//             section {
//                 .wrapper {
//                     width: 100%;
//                 }
//                 .wrapper-inside-post {
//                     width: 100%;
//                 }
//             }
//         }
//     }
// }
// @media screen and (max-height: 800px) {
//     .registration {
//         height: 800px;
//         main {
//             height: 800px;
//         }
//     }
// }
//
// @media screen and (max-height: 700px) and (max-width: 385px) {
//     .registration {
//         height: 1000px;
//         main {
//             height: 1000px;
//         }
//     }
// }
