.login {
    max-width: 100%;
    height: 100%;
    // min-height: 700px;
    background: url('./images/bg.svg') no-repeat;
    background-size: cover;
    color: $color-text;
    text-align: center;
    .header-login {
        @include footer-header(100%, 100px, transparent);
        a {
            text-decoration: none;
        }
        .header-login-image {
            @include image(10px, 16px);
            float: left;
            margin-top: 52px;
            margin-left: 51px;
            font-family: $login-font-family;
            font-size: 18px;
            font-weight: 500;
            color: $color-text;
            line-height: 25px;
            background: url('./images/layer.png') no-repeat;
            .header-login-image-title {
                @include image(55px, 16px);
                margin-left: 13px;
                margin-top: -4px;
            }
        }
        .header-login-image-logo {
            display: inline-block;
            margin: 35px 55px 0 50px;
        }
    }
    main {
        height: 100%;
        padding: 100px 0 89px;
        margin: -100px 0 -89px;
        box-sizing: border-box;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        section {
            margin: auto;
            .wrapper {
                display: inline-block;
                width: 373px;
                border-radius: 10px;
                color: $color-text-wrapper;
                background-color: $color-text;
                .wrapper-inside {
                    height: 509px;
                    border-radius: 10px;
                    background: url('./images/center_login.png') no-repeat;
                    box-shadow: 0 11px 107.4px 23.6px rgba(2, 28, 48, 0.3);
                    font-family: $login-font-family;

                    .login-trolleybus {
                        padding-top: 15px;
                        img {
                            max-width: 100%
                        }
                        .button-close {
                            @include button-close-open-gif(-83px, 33px, hidden)
                        }
                        &:hover {
                            .button-close {
                                @include button-close-open-gif(-83px, 33px, visible)
                            }
                        }
                    }
                    .image-title {
                        @include image(150px, 100px);
                    }
                    .login-input {
                        color: $color-text-input;
                        margin-top: -20px;
                        .wrapper-for-all-inputs {
                            position: relative;
                            .wrapper-for-input {
                                position: relative;
                                z-index: 2;
                                .help-login-button {
                                    @include help-login-registration-button();
                                    display: block;
                                    top: 35px;
                                    left: 280px;
                                    position: absolute;
                                    z-index: 4;
                                }
                                .valid-input {
                                    @include input();
                                }
                                .invalid-input {
                                    @include input-wrong();
                                }
                            }
                            .wrapper-server-registration-link-wrong {
                                @include wrapper-link-error(307px, 100px, 10px, 33px);
                                animation: go-from-left 0.2s normal;
                                z-index: 2;
                            }
                            .wrapper-server-registration-link-wrong-close {
                                @include wrapper-link-error(307px, 100px, 10px, 12px);
                                animation: go-to-left 0.2s normal;
                                z-index: 2;
                            }
                            input.ng-valid-email,
                            input.ng-valid-parse,
                            input.ng-invalid,
                            input.ng-valid-val,
                            input.ng-valid {
                                @include input();
                            }
                            .help-other-login {
                                display: none;
                            }
                            .input-help-animation-email-in {
                                @include help-for-input(220px, 45px, 1);
                                top: -5px;
                                animation: go-left 0.5s normal;
                                z-index: 6;
                            }
                            .input-help-animation-email-out {
                                @include help-for-input(220px, 45px, 0);
                                top: -5px;
                                animation: go-right 0.5s normal;
                                z-index: 6;
                            }
                            .wrong-other-login {
                                display: none;
                            }
                            .input-wrong-animation-in {
                                @include help-for-input(270px, 83px, 1);
                                padding-top: 19px;
                                line-height: 21px;
                                animation: go-left 0.5s normal;
                                z-index: 6;
                                img {
                                    cursor: pointer;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    padding: 11px;
                                }
                            }
                            .input-wrong-animation-out {
                                @include help-for-input(270px, 83px, 0);
                                padding-top: 19px;
                                line-height: 21px;
                                animation: go-right 0.5s normal;
                                z-index: 6;
                                img {
                                    cursor: pointer;
                                }
                            }
                        }
                        .button-submit {
                            @include button-submit(151px, 43px, $background-button-login-color);
                            margin-top: 10px;
                        }
                    }
                    .login-wrapper-for-help {
                        line-height: 13px;
                        margin: 20px auto 17px;
                        text-align: center;
                        cursor: default;
                        .login-for-help {
                            margin: 0 auto;
                            font-family: $login-font-family-button;
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                    .login-social {
                        .image-button {
                            @include image(49px, 49px);
                            padding: 0 12px;
                            cursor: pointer;
                        }
                    }
                    .login-registration-link {
                        font-size: 16px;
                        font-weight: 400;
                        cursor: default;
                        a {
                            color: $color-login-registration-link;
                            font-weight: 500;
                        }
                    }

                }
            }
        }
    }
    .footer-login {
        @include footer-header(100%, 89px, transparent);
        clear: both;
        margin-bottom: 0;
        box-sizing: border-box;
        padding: 20px 0;
        // position: absolute;
        bottom: 0;
        // .footer-login-image {
        //     padding-top: 15px;
        // }
        a {
            padding-left: 73px;
            margin: 0;
            opacity: 1;
            color: white;
            font-family: "Avenir Next";
            font-size: 13px;
            color: white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        div {
            margin-top: 10px;
            margin-left: 75px;
            .fb_link {
                background: url("./images/facebook_icon_light.png") no-repeat center center;
                background-size: contain;
                margin-left: 7px;
                border-radius: 3px;
                display: inline-block;
                width: 20px;
                height: 20px;
                cursor: pointer;
                margin-bottom: -4px;
            }
        }

    }
}
.login-mobile {
    display: none;
    max-width: 375px;
    height: 670px;
    background: url('./images/background.png') no-repeat;
    color: $color-text-wrapper;
    background-color: $color-text;
    text-align: center;
    .login-trolleybus {
        padding-top: 15px;
        img {
            max-width: 100%
        }
        .button-close {
            @include button-close-open-gif(-83px, 33px, hidden)
        }
        &:hover {
            .button-close {
                @include button-close-open-gif(-83px, 33px, hidden)
            }
        }
    }
    .image-title {
        @include image(150px, 100px);
    }
    .login-input {
        color: $color-text-input;
        .wrapper-for-all-inputs {
            margin-top: 80px;
            position: relative;
            .wrapper-for-input {
                position: relative;
                z-index: 2;
                .help-login-button {
                    @include help-login-registration-button();
                    display: block;
                    top: 35px;
                    left: 280px;
                    position: absolute;
                    z-index: 4;
                }
                .valid-input {
                    @include input();
                }
                .invalid-input {
                    @include input-wrong();
                }
            }
            .wrapper-server-registration-link-wrong {
                @include wrapper-link-error(307px, 100px, 10px, 33px);
                animation: go-from-left 0.2s normal;
                z-index: 2;
            }
            .wrapper-server-registration-link-wrong-close {
                @include wrapper-link-error(307px, 100px, 10px, 12px);
                animation: go-to-left 0.2s normal;
                z-index: 2;
            }
            input.ng-valid-email,
            input.ng-valid-parse,
            input.ng-invalid,
            input.ng-valid-val,
            input.ng-valid {
                @include input();
            }
            .help-other-login {
                display: none;
            }
            .input-help-animation-email-in {
                @include help-for-input(220px, 45px, 1);
                top: -5px;
                animation: go-left 0.5s normal;
                z-index: 6;
            }
            .input-help-animation-email-out {
                @include help-for-input(220px, 45px, 0);
                top: -5px;
                animation: go-right 0.5s normal;
                z-index: 6;
            }
            .wrong-other-login {
                display: none;
            }
            .input-wrong-animation-in {
                @include help-for-input(270px, 83px, 1);
                padding-top: 19px;
                line-height: 21px;
                animation: go-up-login 0.5s normal;
                z-index: 6;
                left: 20%;
                bottom: 100%;
            }
            .input-wrong-animation-out {
                @include help-for-input(270px, 83px, 0);
                padding-top: 19px;
                line-height: 21px;
                animation: go-right 0.5s normal;
                z-index: 6;
            }
        }
        .button-submit {
            @include button-submit(151px, 43px, $background-button-login-color);
            margin-top: 15px;
        }
    }
    .login-wrapper-for-help {
        line-height: 13px;
        margin: 20px auto 17px;
        text-align: center;
        cursor: default;
        .login-for-help {
            margin: 35px auto;
            font-family: $login-font-family-button;
            font-size: 14px;
            font-weight: 400;
        }
    }
    .login-social {
        .image-button {
            @include image(49px, 49px);
            padding: 0 12px;
            cursor: pointer;
        }
    }
    .login-registration-link {
        font-family: $login-font-family-button;
        font-size: 16px;
        font-weight: 400;
        cursor: default;
        a {
            color: $color-login-registration-link;
            font-weight: 500;
        }
    }
}
