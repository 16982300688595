.select-city-wrapper {
  width: 100%;
  font-weight: 400;
  font-size: 17px;
  font-family: "Avenir Next";
  .select-city-input {
    width: 257px;
    height: 32px;
    margin: 0 auto 15px;
    padding-bottom: 7px;
    border-bottom: 1px solid #84B7CF;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 15px;
      height: 11px;
      background-image: url("../../../dist/images/arrow_down.png");
      background-repeat: no-repeat;
      -webkit-background-size: 100%;
      background-size: 100%;
      position: absolute;
      right: 5px;
      top: 12px;
    }
  }
  .select-city-drop-down {
    width: 100%;
    .select-city-drop-down-option {
      width: 257px;
      height: 32px;
      margin: 0 auto 15px;
      padding-bottom: 7px;
      border-bottom: 1px solid #84B7CF;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}
