@mixin input() {
    width: 257px;
    height: 32px;
    max-width: 100%;
    margin: 0 auto 15px;// 10
    padding-bottom: 7px;// 5
    font-weight: 400;
    font-size: 17px;
    font-family: $login-font-family;
    border: solid;
    color: $color-text-required;
    outline: none;
    background-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-width: 1px;
    border-bottom-color: #84B7CF;
}
@mixin hide-scroll() {
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
@mixin input-wrong() {
    width: 257px;
    height: 32px;
    max-width: 100%;
    margin: 0 auto 15px;
    padding-bottom: 7px;
    font-weight: 500;
    font-size: 17px;
    font-family: $login-font-family;
    border: solid;
    color: $color-text-input-wrong;
    outline: none;
    background-color: $background-input-wrong;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-width: 1px;
    border-bottom-color: $border-input-wrong;
}
@mixin transition($time) {
    transition: all $time ease;
}
@mixin button-submit($width, $height, $background) {
    width: $width;
    height: $height;
    color: $color-text;
    font-size: 20px;
    border-radius: 7px;
    font-family: $login-font-family;
    font-weight: normal;
    outline: none;
    border-style: none;
    background-color: $background;
    cursor: pointer
}
@mixin image($width, $height) {
    width: $width;
    height: $height;
}
@mixin map-tab($width, $height, $ml, $pr, $pt) {
    width: $width;
    height: $height;
    margin-left: $ml;
    padding-right: $pr;
    padding-top: $pt;
}
@mixin shadow($size1, $size2, $size3, $r, $b, $g, $rad) {
    box-shadow: $size1 $size2 $size3  rgba($r, $b, $g, $rad);
}
@mixin size($width, $height) {
    width: $width;
    height: $height;
}
@mixin footer-header($width, $height, $bg-c) {
    @include size($width, $height);
    background-color: $bg-c;
}
@mixin button-close-open-gif($top, $bottom, $visibility) {
    margin: $top 0 $bottom 12px;
    visibility: $visibility;
    cursor: pointer;
    img {
        margin-top: -4px;
    }
}

@mixin help-for-input($width, $height, $opacity) {
    @include image($width, $height);
    position: absolute;
    opacity: $opacity;
    left: 315px;
    padding-top: 10px;
    font-size: 15px;
    color: $color-text;
    text-align: center;
    background-color: $background-input-help;
    border-radius: 8px;
    box-shadow: 1px 1.7px 4px rgba(0, 0, 0, 0.21);
}

@mixin help-login-registration-button() {
    @include image(20px, 18px);
    padding-top: 2px;
    float: right;
    margin-top: -25px;
    color: $color-text;
    text-align: center;
    background-color: $background-input-help-button;
    border-radius: 20px;
    cursor: pointer;
}

@mixin pop-up($width, $height, $top, $mleft) {
    @include image($width, $height);
    position: absolute;
    top: $top;
    margin-left: $mleft;
    padding: 0;
    color: $color-text-input;
    background-color: $background-popup;
    border-radius: 10px;
    box-shadow: 0 4px 41.7px 1.3px rgba(0, 30, 51, 0.35);
}

@mixin wrapper-link-error($width, $height, $top, $left) {
    @include image($width, $height);
    position: absolute;
    top: $top;
    left: $left;
    color: $color-text;
    background-color: #f36d4f;
    border-radius: 8px;
    box-shadow: 1px 1.7px 4px rgba(0, 0, 0, 0.21);
    .login-registration-link-exit {
        float: right;
        margin: 7px 8px 0 0;
        cursor: pointer;
    }
    .login-registration-link-wrong {
        display: inline-block;
        width: 252px;
        margin: 8px 5px 5px 19px;
        padding-top: 5px;
        font-size: 17px;
        font-weight: 400;
        line-height: 23px;
        text-align: left;
    }
    a {
        font-weight: 500;
        color: $color-text;
        text-decoration: underline;
        cursor: pointer;
    }
}
@mixin list($width, $height, $top, $left, $ground) {
    @include image($width, $height);
    content: '';
    display: block;
    position: absolute;
    left: $left;
    top: $top;
    background-color: $ground;
}
@mixin pop-up-share-copy($width, $height) {
    @include image($width, $height);
    position: absolute;
    top: 210px;
    left: 215px;
    padding-top: 10px;
    border-radius: 7px;
    box-shadow: 0 3px 11.6px 1.4px rgba(4, 24, 38, 0.18);
    background-color: $background-popup;
}
@mixin input-help-animation($left) {
    .login {
        main {
            section{
                .wrapper {
                    .wrapper-inside {
                        .login-input {
                            .wrapper-for-all-inputs {
                                .input-help-animation-email-in {
                                    top: -55px;
                                    left: $left;
                                    animation: go-up 0.5s normal;
                                }
                                .input-help-animation-email-out {
                                    top: -5px;
                                    left: $left;
                                    animation: go-down 0.5s normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .registration {
        main {
            section{
                .wrapper {
                    .wrapper-inside {
                        .registration-input {
                            .wrapper-for-input {
                                .input-help-animation-other-in {
                                    top: -50px;
                                    left: $left;
                                    animation: go-up-other 0.5s normal;
                                }
                                .input-help-animation-other-out {
                                    top: -5px;
                                    left: $left;
                                    animation: go-down-other 0.5s normal;
                                }
                                .input-help-animation-email-in {
                                    top: -55px;
                                    left: $left;
                                    animation: go-up 0.5s normal;
                                }
                                .input-help-animation-email-out {
                                    top: -5px;
                                    left: $left;
                                    animation: go-down 0.5s normal;
                                }
                            }
                        }
                    }
                }
                .wrapper-inside-post {
                    .post-registration-input {
                        .wrapper-for-input {
                            .input-help-animation-other-in {
                                top: -50px;
                                left: $left;
                                animation: go-up-other 0.5s normal;
                            }
                            .input-help-animation-other-out {
                                top: -5px;
                                left: $left;
                                animation: go-down-other 0.5s normal;
                            }
                            .input-help-animation-email-in {
                                top: -55px;
                                left: $left;
                                animation: go-up 0.5s normal;
                            }
                            .input-help-animation-email-out {
                                top: -5px;
                                left: $left;
                                animation: go-down 0.5s normal;
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin input-help-animation-mobile($left) {
    .login-mobile {
        .login-input {
            .wrapper-for-all-inputs {
                .input-help-animation-email-in {
                    top: -55px;
                    left: $left;
                    animation: go-up 0.5s normal;
                }
                .input-help-animation-email-out {
                    top: -5px;
                    left: $left;
                    animation: go-down 0.5s normal;
                }
            }
        }
    }
    .registration-mobile {
        .registration-input {
            .wrapper-for-input {
                .input-help-animation-other-in {
                    top: -43px;
                    left: $left;
                    animation: go-up-other 0.5s normal;
                }
                .input-help-animation-other-out {
                    top: -5px;
                    left: $left;
                    animation: go-down-other 0.5s normal;
                }
                .input-help-animation-email-in {
                    top: -55px;
                    left: $left;
                    animation: go-up 0.5s normal;
                }
                .input-help-animation-email-out {
                    top: -5px;
                    left: $left;
                    animation: go-down 0.5s normal;
                }
            }
        }
        .post-registration-input {
            .wrapper-for-input {
                .input-help-animation-other-in {
                    top: -43px;
                    left: $left;
                    animation: go-up-other 0.5s normal;
                }
                .input-help-animation-other-out {
                    top: -5px;
                    left: $left;
                    animation: go-down-other 0.5s normal;
                }
                .input-help-animation-email-in {
                    top: -55px;
                    left: $left;
                    animation: go-up 0.5s normal;
                }
                .input-help-animation-email-out {
                    top: -5px;
                    left: $left;
                    animation: go-down 0.5s normal;
                }
            }
        }
    }
}
