
.page {
  text-align: center;
  font-family: "Avenir Next";
  @import "partials/popups.scss";

  //choice between stops and trolley buses
  .choose_form {
    position: fixed;
    top: 66px;
    left: 50%;
    z-index: 2;
  }
  .wrapper-back-button {
    position: fixed;
    top: 66px;
    left: 465px;
    z-index: 100000;
    &.left {
      left: 10px;
    }
    .triangle {
      width: 28px;
      height: 26.5px;
      float: left;
      transform: rotate(45deg);
      margin-top: 5px;
      background-color: #5b9ac8;
      border: none;
      border-radius: 5px;
      z-index: 100;
    }
    .back-button {
      width: 74px;
      height: 36px;
      color: white;
      margin-left: -18px;
      font-size: 16px;
      font-weight: 400;
      font-family: "Avenir Next";
      background-color: #5b9ac8;
      border: none;
      border-radius: 5px;
      outline: none;
      z-index: 10000;
      cursor: pointer;
      padding-left: 19px;
    }
    &:hover {
      .triangle {
        background-color: #65a6d6;
      }
      .back-button {
        background-color: #65a6d6;
      }
    }
  }
  .pop-up-wrapper-close-button {
    float: right;
    width: 16px;
    height: 16px;
    margin: 23px 22px 0 0;
    padding: 10px;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color .2s linear;
    .pop-up-close-button {
      float: right;
      width: 16px;
      height: 16px;
      cursor: pointer;
      background-color: transparent;
      background: url("./images/out.svg") no-repeat center center;
      background-size: contain;
    }
    &:hover {
      background-color: #E4EEF5;
      transition: background-color .2s linear;
    }
  }
  .popup.ng-hide-add.ng-hide-add-active {
    opacity: 0;
  }

  //pop-up styles
  .popup {
    overflow: auto;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(33, 93, 136, .3);
    z-index: 100200;
  }

  //find button
  .find-button {
    @include image(27px, 28px);
    position: fixed;
    top: 66.5px;
    right: 140px;
    border-radius: 5px;
    z-index: 1000;
    background: url('./images/GPS.svg') no-repeat;
    background-color: #FFFFFF;
    background-position: -1.6px -1.4px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    cursor: pointer;
    &:active {
      background: url('./images/GPS_2.svg') no-repeat;
      background-color: #FFFFFF;
      background-position: -1.6px -1.4px;
      box-shadow: 0 0 1px black inset, 0 2px 3px black inset, 0 1px 1px black;
    }
    &:hover {
      background: url('./images/GPS_3.svg') no-repeat;
      background-color: #FFFFFF;
      background-position: -1.6px -1.4px;
    }
  }
  .stops {
    @include image(179px, 36px);
    outline: none;
    cursor: pointer;
    float: left;
    opacity: 1;
    background-color: rgba(255, 255, 255, .6);
    color: #525252;
    font-size: 16px;
    font-weight: 400;
    font-family: "Avenir Next";
    border: 0;
    border-radius: 0 6px 6px 0;
    &:hover {
      background-color: rgba(255, 255, 255, .8);
    }
    &.active {
      opacity: 1;
      color: white;
      font-size: 16px;
      font-weight: 500;
      font-family: "Avenir Next";
      background-color: rgba(95, 161, 209, .89);
      &:hover {
        background-color: rgba(95, 161, 209, .89);
      }
    }
  }
  .buses, .list {
    @include image(179px, 36px);
    outline: none;
    cursor: pointer;
    float: left;
    opacity: 1;
    background-color: rgba(255, 255, 255, .6);
    color: #525252;
    font-size: 16px;
    font-weight: 500;
    font-family: "Avenir Next";
    border: 0;
    border-radius: 6px 0 0 6px;
  }
  .buses.active, .list.active {
    opacity: 1;
    color: white;
    font-size: 16px;
    font-weight: 400;
    background-color: rgba(95, 161, 209, .89);
  }
  button.list {
    border-radius: 0;
    display: none;
    border-right: 1px solid rgba(95, 161, 209, 0.89);
    border-left: 1px solid rgba(95, 161, 209, 0.89);
  }

  //sidebar
  .sidebar_wrapper {
    position: absolute;
    height: calc(100vh - 50px);
    width: 100%;
    top: 50px;
    overflow: hidden;
    @include transition(.5s);
    &.active {
      width: 447px;
    }
    .wrapper {
      display: flex;
      height: 100%;
      width: 447px;
      overflow-y: auto;
      -ms-overflow-style: scrollbar;
      overflow-style: scrollbar;
      flex-direction: column;
      background-color: #5FA1D1;
      z-index: 1;
      @import "partials/sidebar.scss";
      @include hide-scroll();
    }
    .wrapper-scroll-not {
      display: flex;
      height: 100%;
      width: 446px;
      overflow: hidden;
      -ms-overflow-style: none;
      flex-direction: column;
      background-color: #5FA1D1;
      @import "partials/sidebar.scss";
      z-index: 1;
    }
  }

  .move_out {
    width: 25px;
    height: 67px;
    position: absolute;
    top: 42%;
    left: 0;
    z-index: 2;
    box-shadow: 4px 1.8px 8.6px 0.4px rgba(0, 0, 0, 0.25);
    border-radius: 0 10px 10px 0;
    background: #4C96CC url("./images/right.png") no-repeat center center;
    cursor: pointer;
    @include transition(.5s);
    &.active {
      left: 446px;
      background: #4C96CC url("./images/left.png") no-repeat center center;
    }
  }

  //map positioning
  .map_wrapper {
    position: relative;
    height: calc(100vh - 50px);
  }
  .angular-google-map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 447px;
    z-index: 1;
    @include transition(.5s);
    &.close {
      left: 0;
    }
  }
  .map-spinner-wrapper {
    opacity: 0;
    z-index: 0;
    display: block;
    @include transition(1s);
    position: absolute;
    right: 0;
    bottom: 0;
    height: calc(100% - 50px);
    background-color: rgba(255, 255, 255, .5);
    width: calc(100% - 445px);
    float: right;
    &.show {
      opacity: 1;
      z-index: 1;
    }
    img {
      margin-top: calc(25% + 45px);
    }
  }

  //header
  .qodef-drop-down-start{
    display: none;
  }
  .hamburger {
    display: none;
  }
  .download {
    width: 100%;
    height: 56px;
    display: block;
  }
  header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    background-color: #2274AF;
    .logo_wrap {
      img {
        margin-top: 9px;
        margin-left: 25px;
      }
    }
    nav {
      ul {
        list-style: none;
        margin: 0;
        li {
          display: inline;
          padding: 16px 0;
          position: relative;
          button {
            color: rgba(255, 255, 255, 0.9);
            font-family: "Avenir Next";
            font-size: 16px;
            font-weight: 400;
            padding: 16px 25px;
            letter-spacing: 1px;
            outline: none;
            cursor: pointer;
            background: none;
            border: none;
            &:hover {
              background-color: #216CA2 !important;
              color: rgba(255, 255, 255, 0.9) !important;
            }
          }
          .header-nav-select-city-popup {
            width: 200px;
            position: absolute;
            z-index: 11;
            display: flex;
            flex-direction: column;
            left: 0;
            span {
              height: 48px;
              color: rgba(255, 255, 255, 0.9);
              font-size: 16px;
              font-family: "Avenir Next";
              font-weight: 400;
              letter-spacing: 1px;
              line-height: 48px;
              border-bottom: 2px solid rgba(255, 255, 255, 0.9);
              cursor: pointer;
              background-color: rgba(34, 116, 175, 0.95);
              &:hover {
                background-color: rgb(34, 116, 175);
              }
              &:last-child {
                height: 50px;
                line-height: 50px;
                border-bottom: none;
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;
              }
            }
            @media (max-width: 1120px) {
              position: relative;
              width: 100%;
              span {
                border: none;
                background-color: transparent;
                font-size: 13px;
                font-weight: 500;
                color: #2274af;
                height: 35px;
                line-height: 35px;
                &:last-child {
                  height: 35px;
                  line-height: 35px;
                }
              }
            }
          }
          .fb_link {
            background: url("./images/facebook_icon_light.png") no-repeat center center;
            background-size: contain;
            margin-left: 7px;
            border-radius: 3px;
            display: inline-block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-bottom: -4px;
          }
          a {
            button {
              color: rgba(255, 255, 255, 0.9);
              font-family: "Avenir Next";
              font-size: 16px;
              font-weight: 400;
              padding: 16px 25px;
              letter-spacing: 1px;
              outline: none;
              cursor: pointer;
              background: none;
              border: none;
              &:hover {
                background-color: #216CA2;
              }
            }
          }
        }
        li:last-child {
          margin-right: 45px;
        }
      }
    }
  }

  //footer
  .bottom_text {
    max-width: 332px;
    width: 100%;
    margin: 12px 0 17px 25px;
    text-align: left;
    flex: 0 0 auto;
  }
  .bottom_text, .bottom_text a {
    color: rgba(255, 255, 255, 0.6);
    font-family: "Avenir Next";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  footer {
    flex: 0 0 auto;
    width: 100%;
    height: 30px;
    padding: 7px 0 6px 25px;
    text-align: left;
    box-sizing: border-box;
    background-color: rgba(86, 151, 199, .95);
    a {
      margin: 0;
      opacity: 1;
      color: white;
      font-family: "Avenir Next";
      font-size: 13px;
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      float: right;
      margin: 3px 20px 0 0;
      font-family: "Avenir Next";
      font-size: 13px;
      color: white;
    }
  }
  .no_favourite {
    color: white;
    font-family: "Avenir Next";
  }

  //spinner
  .wrapper-live-stops-list-open {
    position: relative;
    .spinner_map_wrap {
      position: fixed;
      width: calc(100% - 446px);
      height: 0;
      background-color: rgba(255, 255, 255, .5);
      z-index: 1000;
      bottom: 0;
      right: 0;
      opacity: 0;
      transition: opacity linear 500ms;
      &.show {
        opacity: 1;
        height: calc(100% - 50px);
      }
      .loader {
        position: absolute;
        top: 50%;
        //margin-top: -50px;
        right: 50%;
        width: 100px;
        &:before {
          content: '';
          display: block;
          padding-top: 100%;
        }
      }
      .circular {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite, mapColor 6s ease-in-out infinite;
        stroke-linecap: round;
      }
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }
      @keyframes dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35px;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124px;
        }
      }
      @keyframes mapColor {
        100%,
        0% {
          stroke: #5fa1d1;
        }
      }
    }
    .spinner_list_wrap {
      position: absolute;
      width: 461px;
      height: 533px;
      background-color: transparent;
      z-index: 1000;
      bottom: 0;
      right: 0;
      opacity: 0;
      transition: opacity linear 500ms;
      &.show {
        opacity: 1;
        background-color: rgba(128, 128, 128, 0.34);
      }
      &.hide {
        opacity: 0;
        background-color: transparent;
      }
      .loader {
        position: absolute;
        top: 50%;
        margin-top: -50px;
        right: 50%;
        width: 75px;
        &:before {
          content: '';
          display: block;
          padding-top: 100%;
        }
      }
      .circular {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite, sideBarColor 6s ease-in-out infinite;
        stroke-linecap: round;
      }
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }
      @keyframes dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35px;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124px;
        }
      }
      @keyframes sideBarColor {
        100%,
        0% {
          stroke: #ffffff;
        }
      }
    }
  }

  //infobox
  .infobox {
    background-color: white !important;
    border: none !important;
    padding: 12px 27px 67px !important;
    border-radius: 10px !important;
    box-sizing: border-box !important;
    box-shadow: 1.7px 1px 5.4px 0.6px rgba(0, 0, 0, 0.09) !important;
    p {
      font-family: "Avenir Next";
      margin: 0;
      position: relative;
      z-index: 11;
      i {
        color: #999;
        font-weight: 400;
        padding-right: 5px;
      }
      &.from {
        color: #4d4d4d;
        font-size: 18px;
        font-weight: 600;
      }
      &.to {
        color: #525252;
        font-size: 16px;
        margin-top: 5px;
      }
      &.number {
        font-size: 22px;
        font-weight: 600;
        color: #4d4d4d;
        margin-bottom: 2px;
      }
      &.text {
        color: #9e9d9d;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    img {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 10px;
      transform: rotate(-45deg);
      margin-top: -28px;
      z-index: 10;
    }
  }

  .infobox-inside-route {
    background-color: white !important;
    border: none !important;
    padding: 25px 54px 25px 27px !important;
    border-radius: 10px !important;
    box-sizing: border-box !important;
    box-shadow: 1.7px 1px 5.4px 0.6px rgba(0, 0, 0, 0.09) !important;
    text-align: left;
    .autobus {
      list-style-type: none;
      font-size: 15px;
      margin: 10px 0 0;
      padding: 0;
      font-family: "Avenir Next";
      .bus-buble {
        float: left;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        background-color: transparent;
        background: url("./images/bus-buble.png") no-repeat center center;
        background-size: contain;
      }
      .trollebus-buble {
        float: left;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        background-color: transparent;
        background: url("./images/trolleybus-buble.png") no-repeat center center;
        background-size: contain;
      }
      li {
        padding: 0;
        display: inline-block;
        color: #2274AF;
        line-height: 0px;
        font-size: 16px;
        .content-route {
          float: left;
        }
        a {
          font-family: "Avenir Next";
          font-weight: 700;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        p {
          color: #909090;
          font-weight: 600;
          font-family: "Avenir Next";
        }
      }
    }
    .question-message {
      color: #A3A3A3;
      font-size: 16px;
      padding: 10px 0;
    }
    .buble-wrapper-close-button {
      float: right;
      width: 16px;
      height: 16px;
      margin: -15px -45px 0 0;
      padding: 10px;
      border-radius: 30px;
      cursor: pointer;
      transition: background-color .2s linear;
      .buble-close-button {
        float: right;
        width: 16px;
        height: 16px;
        cursor: pointer;
        background-color: transparent;
        background: url("./images/out.svg") no-repeat center center;
        background-size: contain;
      }
      &:hover {
        background-color: #E4EEF5;
        transition: background-color .2s linear;
      }
    }
    p {
      font-family: "Avenir Next";
      margin: 0;
      position: relative;
      z-index: 11;
      i {
        color: #999;
        font-weight: 400;
        padding-right: 5px;
      }
      &.from {
        color: #4d4d4d;
        font-size: 18px;
        font-weight: 600;
      }
      &.to {
        color: #525252;
        font-size: 16px;
        margin-top: 5px;
      }
      &.number {
        font-size: 22px;
        font-weight: 600;
        color: #4d4d4d;
        margin-bottom: 2px;
      }
      &.text {
        color: #9e9d9d;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    img {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 10px;
      transform: rotate(-45deg);
      margin-top: -28px;
      z-index: -10;
    }
  }
}

.spinner_wrap {
  position: fixed;
  width: 100%;
  height: 0;
  background-color: #60A2D1;
  bottom: -100px;
  right: 0;
  opacity: 0;
  z-index: 1000;
  transition: opacity linear 500ms;
  &.show {
    opacity: 1;
  }
  &.smooth {
    height: 100%;
    bottom: 0;
  }
  .loader {
    position: absolute;
    top: calc(50% - 50px);
    //margin-top: -50px;
    right: calc(50% - 50px);
    width: 100px;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
  .loader-svg {
    position: absolute;
    top: calc(40% - 100px);
    //margin-top: -50px;
    right: calc(60% - 50px);
    width: 100px;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  p {
    font-family: Avenir Next;
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
    position: absolute;
    top: calc(60% - 50px);
    right: calc(54% - 100px);
    width: 110px;
    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
      animation: ellipsis steps(4, end) 1500ms infinite;
      content: "\2026";
      width: 0;
    }
  }
  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, generalColor 6s ease-in-out infinite;
    stroke-linecap: round;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @keyframes generalColor {
    100%,
    0% {
      stroke: #ffffff;
    }
  }
}

.gm-style-mtc {
  margin-top: 6px;
}

.gmnoprint {
  padding: 0;
}

/*IE*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .page {
    .sidebar_wrapper {
      .wrapper-scroll-not {
        width: 444px;
      }
    }
  }
}

_:-ms-fullscreen, :root .selector {
  .page {
    .sidebar_wrapper {
      .wrapper-scroll-not {
        width: 444px;
      }
    }
  }
}
