.tabs {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    margin: 15px 0;
    .page-tab {
        width: 205px;
        height: 40px;
        line-height: 40px;
        font-family: "Avenir Next";
        font-size: 16px;
        font-weight: 600;
        color: rgba(255, 255, 255, .7);
        border: 1px solid white;
        text-decoration: none;
        @include transition(.5s);
        &.blink {
            animation: blur 1s ease-out infinite;
        }
        &.first {
            border-radius: 6px 0 0 6px;
        }
        &.second {
            border-radius: 0 8px 8px 0;
        }
        &:hover {
          background-color: rgb(121, 184, 230);
        }
        &.active {
            background-color: white;
            color: #378CC9;
            &:hover {
              background-color: white;
            }
        }
    }
}
.all_closest {
    flex: 1 0 auto;
    .closest {
        margin-bottom: 3px;
        &.last {
          border-bottom: 0.2px solid rgba(255, 255, 255, 0.3);
        }
        .info {
            height: 60px;
            padding-left: 24px;
            padding-top: 13px;
            position: relative;
            background-color: #ffffff;
            box-sizing: border-box;
            .title {
                color: #525252;
                font-size: 18px;
                font-weight: 600;
            }
            .min_title {
                color: #525252;
                font-size: 14px;
                font-weight: 400;
                i {
                    color: #999;
                    font-weight: 400;
                    font-size: 12px;
                    margin-right: 5px;
                }
            }
            .title, .min_title {
                margin: 0 0 1px 50px;
                text-align: left;
            }
            p {
                display: inline-block;
                width: 35px;
                height: 35px;
                margin: 0;
                float: left;
                color: white;
                font-family: "Avenir Next";
                font-size: 21px;
                font-weight: 700;
                line-height: 35px;
                border-radius: 50%;
            }
            .arrow {
                outline: none;
                display: inline-block;
                position: absolute;
                width: 17px;
                height: 60px;
                right: 0;
                top: 0;
                margin: 0 17px;
                background: url("./images/arrow_up.png") no-repeat center center;
                background-size: contain;
                cursor: pointer;
                &.active {
                  background: url("./images/arrow_down.png") no-repeat center center;
                  background-size: contain;
                }
            }
            .fav_button {
                display: none;
                position: absolute;
                width: 20px;
                height: 20px;
                top: 19px;
                right: 40px;
                display: inline-block;
                cursor: pointer;
                outline: none;
                border: none;
                &.add {
                    background: url("./images/star-21.png") no-repeat center center;
                    background-size: contain;
                    transition: background-image .5s ease;
                    &:hover {
                        background-image: url("./images/star_hover.png");
                    }
                }
                &.clicked {
                    background: url("./images/star-21.png") no-repeat center center;
                    //background-color: blue;
                    background-size: contain;
                }
                &.remove {
                    background: url("./images/star-20.png") no-repeat center center;
                    background-size: contain;
                }
            }
        }
        .one_trolleybus {
            .route_name {
                display: block;
                cursor: pointer;
                height: 60px;
                padding: 0 0 0 38px;
                line-height: 37px;
                text-align: left;
                color: white;
                font-size: 22px;
                font-weight: 600;
                text-decoration: none;
                background-size: 16px;
                box-sizing: border-box;
                //@include transition(.5s);
                .link-wrapper {
                    border-bottom: 1px solid rgba(255, 255, 255, .4);
                    padding: 11px 0;
                    div {
                        display: inline-block;
                    }
                    .circular-route {
                        @include image(16px, 16px);
                        float: left;
                        margin: 7px 9px 0 -25px;
                        color: transparent;
                        font-size: 18px;
                        font-weight: 600;
                        border: 2px solid rgba(255, 255, 255, 0.6);
                        border-radius: 30px;
                    }
                    .allocation {
                        @include image(47px, 35px);
                        text-align: center;
                        color: #498DBF;
                        font-size: 21px;
                        border-right: 2px solid white;
                        border-radius: 5px;
                        background-color: white;
                        opacity: 1;
                    }
                    .time {
                        font-size: 18px;
                        font-weight: 400;
                        opacity: 1;
                    }
                    .time.one {
                        margin-left: 15px;
                        font-size: 22px;
                        font-weight: 600;
                        opacity: 1;
                    }
                    .inner_arrow {
                        outline: none;
                        display: inline-block;
                        height: 35px;
                        width: 13px;
                        float: right;
                        margin-right: 15px;
                        background: url("./images/right.png") no-repeat center center;
                        background-size: contain;
                        cursor: pointer;
                        //@include transition(.5s);
                        opacity: 1;
                        &.active {
                            width: 18px;
                            background: url("./images/arrow_down_white.png") no-repeat center center;
                            background-size: contain;
                            cursor: pointer;
                        }
                    }
                }
                .link-wrapper-last { // Don't change that!
                    border-bottom: 1px solid rgba(255, 255, 255, .4);
                    padding: 11px 0;
                    div {
                        display: inline-block;
                    }
                    .circular-route {
                        @include image(16px, 16px);
                        float: left;
                        margin: 7px 9px 0 -25px;
                        color: transparent;
                        font-size: 18px;
                        font-weight: 600;
                        border: 2px solid rgba(255, 255, 255, 0.6);
                        border-radius: 30px;
                    }
                    .allocation {
                        @include image(47px, 35px);
                        text-align: center;
                        color: #498DBF;
                        border-radius: 5px;
                        background-color: white;
                        opacity: 1;
                    }
                    .time {
                        font-size: 18px;
                        font-weight: 400;
                        opacity: 1;
                    }
                    .time.one {
                        margin-left: 15px;
                        font-size: 22px;
                        font-weight: 500;
                        opacity: 1;
                    }
                    .inner_arrow {
                        outline: none;
                        display: inline-block;
                        height: 35px;
                        width: 13px;
                        float: right;
                        margin-right: 15px;
                        background: url("./images/right.png") no-repeat center center;
                        background-size: contain;
                        cursor: pointer;
                        //@include transition(.5s);
                        opacity: 1;
                        &.active {
                            width: 18px;
                            background: url("./images/arrow_down_white.png") no-repeat center center;
                            background-size: contain;
                            cursor: pointer;
                        }
                    }
                }
            }
            .last {
                .route_name {
                    .link-wrapper-last, .link-wrapper {
                        border-bottom: none;
                    }
                }
            }

            .wrapper-live-stops-list {
                display: none;
            }
            .wrapper-live-stops-list-open {
                display: block;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                animation: down-list 0.5s normal;
            }

            .route_name.active {
                background-color: #A6BE66;
                .link-wrapper {
                    border-bottom: 0 solid transparent;
                    .circular-route {
                          border: 6px solid #FFFFFF;
                          width: 8px;
                          height: 8px;
                          margin-top: 7px;
                    }
                    .allocation {
                        color: #A6BE66;
                    }
                }
                .link-wrapper-last {
                    .circular-route {
                        border: 6px solid #FFFFFF;
                        width: 8px;
                        height: 8px;
                        margin-top: 7px;
                    }
                    .allocation {
                        color: #A6BE66;
                    }
                }
            }
        }

        .trolleybuses_wrapper {
            max-height: 0;
            overflow-y: hidden;
            overflow-x: hidden;
            @include transition(.5s);
            & > div {
                display: none;
            }
            &.active {
                max-height: 1000px;
                & > div {
                    display: block;
                }
            }
            .title-buses {
                height: 26px;
                padding: 19px 24px 0;
                font-size: 13px;
                font-weight: 400;
                letter-spacing: 3px;
                color: rgba(255, 255, 255, .8);
                font-family: "Avenir Next";
                text-align: left;
                text-transform: uppercase;
                background-color: #4d94c8;
                border-bottom: 0.2px solid rgba(255, 255, 255, 0.35);
                border-top: 0.2px solid rgba(255, 255, 255, 0.35);
                .title-buses-return {
                    height: 14px;
                    float: right;
                    font-size: 13px;
                    font-family: "Avenir Next";
                    font-weight: 500;
                    letter-spacing: 1px;
                    color: rgba(255, 255, 255, .4);
                    cursor: pointer;
                    text-align: left;
                    text-transform: none;
                    border-bottom: 0.2px solid rgba(255, 255, 255, 0.35);
                    &:hover {
                        border-bottom: 0.2px solid rgba(255, 255, 255, 0.35);
                        color: #FFFFFF;
                    }
                }
            }
            .wrapper-sidebar-list-loader {
                height: 100px;
                width: 100%;
                .sidebar-list-loader {
                    height: 60px;
                    margin-top: 10%;
                    max-width: 100%;
                    margin-left: calc(100% - 250px);
                }
            }
        }
        &.added {
            .info {
                background: #FFFFFF url('./images/otherPin.png') no-repeat 30px;
                background-size: 25px;
            }
            p {
                background-color: white;
            }
        }
        &.favourite {
            .info {
                background: #FFFFFF url('./images/otherPin.png') no-repeat 30px;
                background-size: 25px;
            }
        }
        &.one {
            p {
                background-color: #F36D4F;
            }
        }
        &.two {
            p {
                background-color: #A6BE66;
            }
        }
        &.thre {
            p {
                background-color: #3F85B8;
            }
        }
        .sorry {
            padding: 5px 0;
            .sorry-warning {
                @include image(39px, 31px);
                float: left;
                margin: 11px 0 0 8px;
                padding-top: 8px;
                color: white;
                font-size: 22px;
                border: 2px solid #FFFFFF;
                border-radius: 30px;
            }
            p {
                background-color: transparent;
                color: white;
                margin: 10px 0;
            }
        }
        .wrapper-live-stops-list,
        .wrapper-live-stops-list-open {
            width: 100%;
            height: 533px;
            font-size: 20px;
            text-align: left;
            overflow-y: auto;
            overflow-x: hidden;
            @include hide-scroll();
            .wrapper-list {
                margin-left: 40px;
                border-left: 2px solid #9fc6e3;
                color: $color-stop-text;
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li {
                        margin: 0 0 0 29px;
                        font-size: 18px;
                        position: relative;
                        @include transition(.5s);
                        div {
                            padding: 15px 0 14px 5px;
                            border-bottom: 1px solid rgba(255, 255, 255, .2);
                            span {
                                font-size: 18px;
                            }
                        }
                        &:first-of-type {
                            opacity: 1;
                            div {
                                opacity: 0.6;
                            }
                            &:before {
                                @include list(13px, 2px, calc(50% - 3.5px), -36px, #9fc6e3);
                                border-radius: 0;
                            }
                            &:after {
                                @include list(2px, 21.5px, 0, -31px, #5FA1D1);
                                opacity: 1;
                            }
                        }
                        &:last-of-type {
                            opacity: 1;
                            div {
                                opacity: 0.8;
                                border: none;
                            }
                            &:before {
                                @include list(13px, 2px, calc(50% - 2.5px), -36px, #9fc6e3);
                                border-radius: 0;
                            }
                            &:after {
                                @include list(2px, 26px, 24px, -31px, #5FA1D1);
                                opacity: 1;
                            }
                        }
                        &:before {
                            @include list(8px, 8px, calc(50% - 3px), -34px, rgba(255, 255, 255, .8));
                            border-radius: 50%;
                        }
                    }
                    .white {
                        font-weight: 500;
                        &:before {
                            @include list(10px, 10px, calc(50% - 3px), -35px, white);
                        }
                    }
                    .dark {
                        font-weight: 400;
                        color: rgba(255, 255, 255, .4);
                    }
                    .grey {
                        font-weight: 400;
                    }
                }
            }
        }
        .hide_list {
            display: none;
            margin-top: 1px;
            @include image(428px, 20px);
            padding: 10px 0 0 20px;
            color: rgba(255, 255, 255, 0.8);
            font-size: 10px;
            font-weight: 400;
            letter-spacing: 2px;
            text-transform: uppercase;
            text-align: center;
            border-top: 1px solid rgba(255,255,255,0.4);
            background: #66A8D8 url("./images/up_min.svg") no-repeat 179px 10px;
            background-size: 11px;
            cursor: pointer;
            img {
                float: left;
                margin-left: 172px;
            }
            p {
                float: left;
                margin: 0;
                background: transparent;
            }
        }
    }
}

@media screen and (min-width : 1321px) {
    .all_closest {
        .closest {
            .one_trolleybus {
                .route_name {
                    .link-wrapper {
                        .inner_arrow {
                            margin-right: 40px;
                        }
                    }
                    .link-wrapper-last {
                        .inner_arrow {
                            margin-right: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-height : 1000px) {
    .all_closest {
        .closest {
            .one_trolleybus {
                .route_name {
                    .link-wrapper {
                        .inner_arrow {
                            margin-right: 40px;
                        }
                    }
                    .link-wrapper-last {
                        .inner_arrow {
                            margin-right: 40px;
                        }
                    }
                }
            }
        }
    }
}
