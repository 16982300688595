@import "./normalize.css/normalize.css";
@import "./font-awesome/css/font-awesome.css";
@import "styles/helpers/variables";
@import "styles/helpers/mixins";
@import "styles/helpers/animations";
@import "styles/login";
@import "styles/map";
@import "styles/page";
@import "styles/registration";
@import "styles/selectSity.scss";
@import "styles/mobile";
@import "./fonts/font.css";

* { -webkit-tap-highlight-color: rgba(0,0,0,0); }

html,
body,
ui-view {
    height: 100%;
}
body {
    [ng\:cloak], [ng-cloak], .ng-cloak {
      display: none;
    }
}
input {
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-moz-placeholder,
  &:-ms-input-placeholder {
      color: $color-text-required !important;
      opacity: 0.7;
  }
  .ng-invalid-val {
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
        color: $color-text-input-wrong !important;
        opacity: 0.7;
    }
  }
}
// input::-webkit-input-placeholder {
//     color: $color-text-required !important;
//     opacity: 0.7;
// }
// input::-moz-placeholder {
//     color: $color-text-required !important;
//     opacity: 0.7;
// }
// input:-moz-placeholder {
//     color: $color-text-required !important;
//     opacity: 0.7;
// }
// input:-ms-input-placeholder {
//     color: $color-text-required !important;
//     opacity: 0.7;
// }

// input.ng-invalid-val::-webkit-input-placeholder {
//     color: $color-text-input-wrong;
//     opacity: 0.6;
// }
// input.ng-invalid-val::-moz-placeholder {
//     color: $color-text-input-wrong;
//     opacity: 0.6;
// }
// input.ng-invalid-val:-moz-placeholder {
//     color: $color-text-input-wrong;
//     opacity: 0.6;
// }
// input.ng-invalid-val:-ms-input-placeholder {
//     color: $color-text-input-wrong;
//     opacity: 0.6;
// }


.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.gmnoprint {
  padding: 0 !important;
  &.left {
    padding-left: calc(100% - 147px) !important;
  }
}

.gm-style-mtc {
  div:last-of-type {
    display: none;
  }
}

.download {
  display: none;
  width: 100%;
  background-color: #efefef;
  .exit {
    float: left;
    width: 16px;
    padding: 50px 10px 50px 10px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .img {
    float: left;
    margin: 20px 10px 0;
    .logo {
      width: 70px;
      height: 70px;
      border: none;
      outline: none;
    }
  }
  .description {
    display: table;
    width: calc(100% - (97px + 4%));
    p {
      float: left;
      max-width: calc(70% - 25px);
      margin: 50px 5% 30px;
      font-size: 15px;
      font-weight: 400;
      font-family: "Avenir Next";
      color: black;
      letter-spacing: 1px;
    }
    a {
      float: right;
      margin-top: 40px;
      padding: 10px 25px 30px 0;
      width: 20%;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      font-family: "Avenir Next";
      color: #3f78a9;
      letter-spacing: 1px;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
@media (max-width: 1120px) {
  .download {
    display: table;
    z-index: 1;
    position: relative;
  }
  .login,
  .registration {
    main {
      z-index: 0;
    }
  }
  .left{
    left: 0px;
  }
}
@media (max-width: 720px) {
  .download {
    .description {
      width: calc(100% - (143px + 4%));
    }
  }
}
@media (max-width: 500px) {
  .download {
    .img {
      margin: 20px 10px 0 0;
    }
    .description {
      width: calc(100% - (143px + 4%));
      p {
        margin: 20px 5% 30px;
      }
      a {
        margin-top: 30px;
        padding: 10px 0 30px 0;
        width: calc(20% + 25px);
      }
    }
  }
}
@media (max-width: 360px) {
  .download {
    .img {
      margin: 25px 10px 0 0;
    }
    .description {
      p {
        margin: 20px 0 30px;
      }
      a {
        margin-top: 40px;
        width: calc(30% + 25px);
      }
    }
  }
}
