@media only screen
and (max-device-width : 1120px)
and (orientation : landscape), (max-device-width : 1120px)
and (orientation : portrait), (max-device-width : 1120px)
and (-webkit-min-device-pixel-ratio: 1) {
    .page {
        .sidebar_view {
            .sidebar_wrapper {
                .wrapper, .wrapper-scroll-not {
                    width: 100%!important;
                }
            }
        }
    }
}
// @media not all and (min-resolution:.001dpcm) and (max-width: 1120px) { @supports (-webkit-appearance:none) {
//     // safari on ios
//     .page header nav ul li button:hover {
//         background: none !important;
//         color: #2575ae !important;
//     }
// }}
@media (max-width: 1120px) {
    .gm-style-mtc,
    .gm-svpc {
      display: none;
    }
    .page {
        position: relative;
        height: 100%;
        .find-button {
          right: 10px;
        }
        .infobox-inside-route {
          padding: 25px 24px 25px 27px !important;
          .buble-wrapper-close-button {
            margin: -15px -16px 0 0;
          }
          .autobus {
            li {
              a,
              p {
                font-size: 20px;
              }
            }
          }
        }
        .wrapper-back-button {
          top: 6px;
          left: 2%;
          width: 50px;
          .triangle,
          .back-button {
            display: none
          }
          .arrow-back {
            width: 50px;
            height: 42px;
            background: url(./images/arrow-back.svg) no-repeat;
            background-size: 17px;
            background-position: center 4px;
            cursor: pointer;
          }
        }
        .map-spinner-wrapper {
            width: 100%;
        }
        .pop-up-share {
            height: 405px;
            .pop-up-share-title-under, .pop-up-share-scan-wrapper {
                display: none;
            }
            .pop-up-rating-link-wrapper, .pop-up-rating-or {
                display: block;
            }
            .pop-up-share-title-for {
                margin-bottom: 35px;
            }
            .pop-up-share-copy-show {
                top: 170px;
            }
            .pop-up-share-copy-hide {
                top: 170px;
            }
            .pop-up-rating-link-wrapper {
                display: inline-block;
                .pop-up-rating-link {
                    display: inline-block;
                    padding-bottom: 2px;
                    color: $color-text-popup-link;
                    font-size: 24px;
                    font-weight: 600;
                    text-decoration: none;
                    border-bottom: 1px solid rgba(85, 149, 196, .3);
                    margin-left: 5px;
                }
                .pop-up-rating-if {
                    color: $color-text-popup-rating-description;
                    font-size: 14px;
                    font-weight: 500;
                    font-style: italic;
                    margin-top: 10px;
                }
                &.left {
                    float: left;
                    margin-left: 110px;
                }
                &.right {
                    float: left;
                    padding-top: 2px;
                }
            }
            .pop-up-rating-or {
                float: left;
                line-height: 34px;
                margin: 0 40px;
                color: $color-text-popup-rating-text;
                font-size: 24px;
                font-weight: 500;
            }
        }
        .wrapper-live-stops-list {
            .spinner_map_wrap.show {
                width: 100%;
                .loader {
                    top: calc(50% - 50px);
                    right: calc(50% - 50px);
                }
            }
        }
        .wrapper-live-stops-list-open {
            width: calc(100% + 16px);
            .spinner_map_wrap.show {
                width: 100%;
                .loader {
                    top: calc(50% - 50px);
                    right: calc(50% - 50px);
                }
            }
        }
        // header + mobile menu
        .hamburger {
            display: block;
            position: fixed;
            z-index: 2;
            height: 50px;
            width: 100%;
            color: white;
            text-align: right;
            background-color: #5fa1d1;
            padding: 12.5px;
            box-sizing: border-box;
            @include transition(.5s);
            &.active {
                padding-right: 252.5px;
            }
            i {
                font-size: 25px;
                cursor: pointer;
            }
        }
        .overlay {
            position: fixed;
            width: 100%;
            height: 100%;
            bottom: 0;
            z-index: 0;
            background-color: rgba(33,93,136,0.3);
            transition: opacity .5s;
            opacity: 0;
            &.active {
                z-index: 3;
                opacity: 1;
            }
        }
        header {
            position: fixed;
            height: 100%;
            z-index: 1001;
            width: 75%;
            max-width: 240px;
            display: block;
            right: -240px;
            background-color: white;
            @include transition(.5s);
            &.active {
                right: 0;
                box-shadow: -5px -1px 15px 0px rgba(50,50,50,0.3);
            }
            .logo_wrap {
                background-color: #5fa1d1;
                height: 50px;
                img {
                    margin-left: 0;
                }
            }
            nav {
                ul {
                    padding: 0;
                    margin-top: 10px;
                    li {
                        display: block;
                        margin-left: 0;
                        padding-top: 12px;
                        button {
                            padding: 0;
                            display: block;
                            width: 100%;
                            max-width: 180px;
                            margin-left: 40px;
                            font-size: 13px;
                            font-weight: 500;
                            border-bottom: 1px solid rgba(0, 0, 0, .1);
                            padding: 7px;
                            text-align: left;
                            color: #2274af;
                            cursor: pointer;
                            &:hover {
                                background-color: transparent !important;
                                color: #2274af !important;
                            }
                        }
                        &:nth-child(1) {
                            background: url("./images/star_sidebar.png") no-repeat 10px 17px;
                            background-size: 19px;
                        }
                        &:nth-child(2) {
                            display: none;
                        }
                        &:nth-child(3) {
                            button:after{
                                content: ' або завантажити';
                            }
                            background: url("./images/share_sidebar.png") no-repeat 10px 17px;
                            background-size: 19px;
                        }
                        &:nth-child(4) {
                            display: none;
                            background: url("./images/star_sidebar.png") no-repeat 10px 17px;
                            background-size: 19px;
                        }
                        &:nth-child(5) {
                            //display: none;
                            background: url("./images/smile_sidebar.png") no-repeat 10px 17px;
                            background-size: 19px;
                        }
                        &:nth-child(5) {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            padding: 0;
                            background: url("./images/exit.png") no-repeat 70px 17px;
                            background-size: 19px;
                            button {
                                border-bottom: none;
                                border-top: 1px solid rgba(0, 0, 0, .1);
                                padding: 20px;
                                line-height: 15px;
                                max-width: 100%;
                                margin: 0;
                                font-weight: 600;
                                text-align: center;
                            }
                        }
                        &:last-child {
                            display: none;
                        }
                    }
                }
            }
        }
        // map
        .map_wrapper {
            position: initial;
        }
        .angular-google-map-container {
            left: 0;
        }
        // sidebar
        .move_out {
            display: none;
        }
        .sidebar_view {
            .sidebar_wrapper {
                width: 100% !important;
                height: 302px;
                top: calc(100% - 302px);
                &.active {
                    height: 50px;
                    top: calc(100% - 50px);
                }
                &.tall {
                    height: 70%;
                    top: 30%;
                }
                .wrapper, .wrapper-scroll-not {
                    position: relative;
                    width: 100%;
                    .all_closest {
                        margin-top: 50px;
                        .closest {
                            &.last {
                              border-bottom: 0.2px solid rgba(255, 255, 255, 0.3);
                            }
                            .one_trolleybus {
                                .route_name {
                                    height: 50px;
                                    line-height: 50px;
                                    margin-bottom: 1px;
                                    .link-wrapper, .link-wrapper-last {
                                        padding: 0;
                                        height: 50px;
                                        .circular-route {
                                            margin-top: 16px;
                                        }
                                        .inner_arrow {
                                            margin-top: 8px;
                                        }
                                        .allocation {
                                            vertical-align: middle;
                                            line-height: 38px;
                                        }
                                    }
                                    .link-wrapper-last {
                                        border-bottom: none;
                                    }
                                }
                            }
                            .wrapper-live-stops-list {
                                width: calc(100% + 17px);
                            }
                            .wrapper-live-stops-list-open {
                                width: calc(100% + 17px);
                            }
                            .hide_list {
                                width: 100%;
                                display: block;
                                background-position-x: calc(50% - 35px);
                            }
                        }
                    }
                    .tabs {
                        margin: 0;
                        // position: fixed;
                        position: absolute;
                        width: 100%;
                        z-index: 1;
                        overflow: hidden;
                        //&.ios {
                        //    position: absolute;
                        //}
                        .page-tab {
                            height: 50px;
                            box-sizing: border-box;
                            border: none;
                            width: 50%;
                            padding: 6px 0;
                            border-radius: 0;
                            background-color: #2274af;
                            color: rgba(255, 255, 255, .5);
                            &.active {
                                background-color: #5fa1d1;
                                color: #ffffff;
                            }
                            // &.second {
                            //   // .last {
                            //   //     .route_name {
                            //   //         border-bottom: 0.2px solid rgba(255,255,255,0.35);
                            //   //         &.active {
                            //   //             border: none;
                            //   //         }
                            //   //     }
                            //   // }
                            // }
                        }
                    }
                }
                .wrapper-scroll-not {
                    width: 100%;
                }
            }
        }
        // footer
        .bottom_text {
            width: calc(100% - 25px);
            display: none;
        }
        footer {
            display: none;
        }
        // live view buttons
        button.list {
            display: inline-block;
            border: none;
        }
        button.buses.active, button.list.active, button.stops.active {
            background-color: #fff;
            color: #5fa1d1;
        }
        button.buses, button.list, button.stops {
            background-color: #5fa1d1;
            color: #fff;
            border: 1px solid #fff;
        }
        button.stops {
          &:hover {
            background-color: #5fa1d1;
          }
          &.active {
            &:hover {
              background-color: #fff;
            }
          }
        }
        // top buttons
        .choose_form {
            left: calc(50% - 139px);
            position: fixed;
            top: 7px;
            z-index: 2;
        }
        .hidden_div {
            position: absolute;
            bottom: 0;
            height: 0;
            width: 100%;
            cursor: pointer;
            &.active {
                height: 50px;
                z-index: 999;
            }
        }
    }
    .login {
        background: url('./images/bg.svg') no-repeat fixed;
    }
    .registration {
        background: url('./images/bg.svg') no-repeat fixed;
    }
}
@media (max-width: 900px) {
    .page {
        .pop-up-feedback {
            .wrapper-for-input {
                .input-help-animation-pop-up-in {
                    animation: go-up-pop-up 0.5s normal;
                    left: 200px;
                    margin-top: -32px;
                }
                .input-help-animation-pop-up-out {
                    animation: go-down-pop-up 0.5s normal;
                    left: 200px;
                    margin-top: -32px;
                }
            }
        }
    }

}

@media (max-width: 800px) {
    .login {
        // new // height: calc(100% + 20px);
        .header-login {
          .header-login-image {
            .header-login-image-title {
                margin-top: -5px;
            }
          }
        }
        main {
            height: 100%;
            position: fixed;
            top: 0;
            width: 100%;
            margin: 10px 0 10px 0;
            padding: 0 10px;
            section {
                .wrapper {
                    max-width: 373px;
                    width: 100%;
                }
            }
        }
        .footer-login {
            display: none;
        }
        .header-login {
            height: 55px;
            a {
                display: block;
                cursor: pointer;
                position: relative;
                width: 70px;
                margin: 0 auto;
                z-index: 10;
            }
            .header-login-image-logo {
                display: none;
            }
            .header-login-image {
                margin: 0 auto;
                padding-top: 27px;
                background-position-y: 25px;
                float: none;
                width: 70px;
            }
        }
    }
    // registration page
    .registration {
        // new // height: calc(100% + 20px);
        .header-registration {
            margin: 0 auto;
            float: none;
            width: 70px;
            height: 55px;
            padding-top: 10px;
            position: relative;
            z-index: 10;
            .header-registration-image {
                margin-top: 15px;
                margin-left: 0;
                .header-registration-image-title {
                    padding-top: 2px;
                    margin-top: -5px;
                }
            }
            .header-registration-image-logo {
                display: none;
            }
        }
        .footer-registration {
            display: none;
        }
        main {
            margin: 10px 0 10px 0;
            height: 100%;
            width: 100%;
            position: fixed;
            top: 0;
            padding: 0 10px;
        }
    }
}

@media (max-height: 800px) {
    .page {
        .pop-up-share {
            top: 15%;
        }
    }
    .login {

        // new // height: calc(100% + 20px);
        main {
            height: 100%;
            position: fixed;
            top: 0;
            width: 100%;
            margin: 10px 0 10px 0;
            padding: 0 10px;
            section {
                .wrapper {
                    max-width: 373px;
                    width: 100%;
                }
            }
        }
        .footer-login {
            display: none;
        }
        .header-login {
            height: 55px;
            a {
                display: block;
                cursor: pointer;
                position: relative;
                width: 70px;
                margin: 0 auto;
                z-index: 10;
            }
            .header-login-image-logo {
                display: none;
            }
            .header-login-image {
                margin: 0 auto;
                padding-top: 27px;
                background-position-y: 25px;
                float: none;
                width: 70px;
            }
        }
    }
    // registration page
    .registration {
        // new // height: calc(100% + 20px);
        .header-registration {
            margin: 0 auto;
            float: none;
            width: 70px;
            height: 55px;
            padding-top: 10px;
            position: relative;
            z-index: 10;
            .header-registration-image {
                margin-top: 15px;
                margin-left: 0;
                .header-registration-image-title {
                    padding-top: 2px;
                }
            }
            .header-registration-image-logo {
                display: none;
            }
        }
        .footer-registration {
            display: none;
        }
        main {
            margin: 10px 0 10px 0;
            height: 100%;
            width: 100%;
            position: fixed;
            top: 0;
            padding: 0 10px;
        }
    }
}

@media (max-height: 730px) {
    // .registration .header-registration, .login .header-login a {
    //     z-index: 0!important;
    // }
    .registration {
        .header-registration {
            display: none;
            a {
                text-decoration: none;
            }
        }
        main {
            padding: 0 0 89px;
            margin: 0 0 -89px;
        }
    }
    .login {
        padding: 0 0 89px;
        margin: 0 0 -89px;
        .header-login {
            display: none;
            a {
                text-decoration: none;
            }
        }
        main {
            padding: 0 0 89px;
            margin: 0 0 -89px;
        }
    }
}

@media (max-height: 620px) {
    .registration {
        main {
            overflow: auto;
        }
    }
    .page {
        .pop-up-share {
            top: 0;
        }
    }
}

@media (max-height: 520px) {
    .login {
        main {
            overflow: auto;
        }
    }
}

@media (max-width: 700px) {
    // popup
    .page {
        .choose_form {
            left: calc(50% - 73px);
        }
        button.list, button.buses, button.stops {
            width: 75px;
        }
        .pop-up-share {
            height: 530px;
            .pop-up-share-copy-show, .pop-up-share-copy-hide {
                left: calc(50% - 110px) !important;
            }
            .pop-up-rating-link-wrapper {
                &.left, &.right {
                    float: none;
                    margin: 0;
                    margin-bottom: 10px;
                }
            }
            .pop-up-rating-or {
                float: none;
                margin: 0;
                margin-bottom: 15px;
            }
        }
        .pop-up-feedback, .pop-up-share, .pop-up-rating, .pop-up-question {
            left: 15px;
            width: calc(100% - 30px);
        }
        .pop-up-rating {
            height: 340px;
            .pop-up-rating-title {
                width: 80%;
                margin: 45px auto 25px auto;
                font-size: 22.5px;
                line-height: 28px;
            }
            .pop-up-rating-link-wrapper {
                &.left, &.right {
                    float: none;
                    margin: 0;
                    margin-bottom: 10px;
                }
            }
            .pop-up-rating-or {
                float: none;
                margin: 0;
                margin-bottom: 15px;
            }
        }
        .pop-up-feedback {
            .pop-up-feedback-title {
                font-size: 22.5px;
            }
            input {
                max-width: 580px;
                width: 80%;
                font-size: 15px;
                box-sizing: border-box;
            }
            input.ng-valid {
                margin: 20px 10px 0 10px;
                width: calc(100% - 20px);
            }
            .wrapper-for-input {
                .invalid-input {
                    margin: 20px 10px 0 10px;
                    width: calc(100% - 20px);
                }
                .valid-input {
                    margin: 20px 10px 0 10px;
                    width: calc(100% - 20px);
                }
                .help-header-button {
                    margin-top: 25px;
                    left: calc(90% - 10px);
                }
                .input-help-animation-pop-up-in {
                    margin-top: -28px;
                }
                .input-help-animation-pop-up-out {
                    margin-top: -28px;
                }
            }
            .pop-up-button {
                font-size: 18px;
            }
        }
        .pop-up-share {
            .pop-up-share-title {
                font-size: 22.5px;
            }
            .pop-up-share-title-under {
                font-size: 16.5px;
                margin-bottom: 35px;
            }
            .pop-up-share-title-for {
                font-size: 18px;
                line-height: 28px;
                padding: 0 10px;
            }
        }
        .pop-up-question {
            .pop-up-question-title {
                font-size: 22.5px;
            }
            .pop-up-question-description {
                font-size: 15px;
            }
            .pop-up-question-button-yes {
                // width: 200px;
                font-size: 18px;
            }
            .pop-up-question-button-no {
                font-size: 18px;
                // width: 200px;
            }
        }
        // sidebar
        .sidebar_view {
            .sidebar_wrapper {
                .wrapper, .wrapper-scroll-not {
                    .no_favourite {
                        font-size: 12px;
                        font-weight: 600;
                        margin-top: 20px;
                    }
                    .tabs {
                        .page-tab {
                            font-size: 15px;
                        }
                    }
                    .all_closest {
                        .closest {
                            .wrapper-live-stops-list {
                                .wrapper-list {
                                    ul {
                                        li {
                                            div {
                                                span {
                                                    font-size: 13px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.favourite, &.added {
                                .info {
                                    background: #fff url(./images/otherPin.png) no-repeat 20px 14px;
                                    background-size: 20px;
                                    .min_title {
                                        i {
                                            margin-right: 2px;
                                        }
                                    }
                                }
                            }
                            .info {
                                padding-left: 14px;
                                .title {
                                    font-size: 13px;
                                    margin: 0 0 5px 45px;
                                }
                                .min_title {
                                    font-size: 12px;
                                    margin: 0 0 1px 45px;
                                }
                                p {
                                    font-size: 18px;
                                    line-height: 32px;
                                    width: 30px;
                                    height: 30px;
                                }
                                .arrow {
                                    background-size: 16px;
                                }
                                .fav_button {
                                    background-size: 20px;
                                }
                            }
                            .trolleybuses_wrapper {
                                .title-buses {
                                    font-size: 10px;
                                    padding: 19px 0 0 14px;
                                }
                            }
                            .one_trolleybus {
                                .route_name {
                                    // padding: 0 0 0 15px;
                                    font-size: 18px;
                                    .allocation {
                                        padding-bottom: 5px;
                                        width: 42px;
                                        height: 30px;
                                        line-height: 33px;
                                    }
                                    .link-wrapper {
                                        .allocation {
                                            font-size: 17px;
                                        }
                                        .time {
                                            font-size: 14px;
                                            &.one {
                                                font-size: 17px;
                                            }
                                        }
                                        .inner_arrow {
                                            background-size: 11px;
                                        }
                                    }
                                    .link-wrapper-last {
                                        .time {
                                            font-size: 14px;
                                            &.one {
                                                font-size: 17px;
                                            }
                                        }
                                        .inner_arrow {
                                            background-size: 11px;
                                        }
                                    }
                                }
                            }
                            .sorry {
                                .sorry-warning {
                                    @include image(33px, 27px);
                                    margin-top: 10px;
                                    padding-top: 5px;
                                }
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        // choose buttons
        button.list, button.buses, button.stops {
            font-size: 12px!important;
        }
    }
    @include input-help-animation(75px);
}

@media (max-width: 570px) {
    .page {
        .pop-up-question {
            .pop-up-question-description {
                margin: 0 20px 30px 20px;
            }
        }
        .pop-up-feedback {
            .wrapper-for-input {
                .input-help-animation-pop-up-in {
                    left: calc(40% - 20px);
                }
                .input-help-animation-pop-up-out {
                    left: calc(40% - 20px);
                }
            }
        }
    }
}

@media (max-width: 560px) {
    .page {
        .pop-up-feedback {
            .wrapper-for-input {
                .help-header-button {
                    left: 90%;
                }
            }
        }
    }
}
@media (max-width: 535px) {
    .page {
        .pop-up-question {
            height: 366px;
            .pop-up-question-description {
                margin: 0 20px -3px 20px;
            }
            .pop-up-question-button-yes {
                margin-left: 20px;
            }
        }
    }
}

@media (max-width: 501px) {
    // popup
    .page {
        .pop-up-rating {
            height: 350px;
        }
        .pop-up-feedback {
            .wrapper-for-input {
                .input-help-animation-pop-up-in {
                    left: calc(40% - 50px);
                }
                .input-help-animation-pop-up-out {
                    left: calc(40% - 50px);
                }
            }
        }
    }
}

@media (max-width: 480px) {
    // popup
    .page {
        .pop-up-share {
            height: 555px;
        }
    }
}

@media (max-width: 407px) {
    // popup
    .page {
        .pop-up-question {
            height: 403px;
        }
    }
}

@media (max-width: 399px) {
    .login {
        main {
            section{
                .wrapper {
                    .wrapper-inside {
                        .login-input {
                            .wrapper-for-all-inputs {
                                .wrapper-for-input {
                                    .help-login-button {
                                        left: 260px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .registration {
        main {
            section{
                .wrapper {
                    .wrapper-inside {
                        .registration-input {
                            .wrapper-for-input {
                                .help-registration-button {
                                    left: 260px;
                                }
                            }
                        }
                    }
                }
                .wrapper-inside-post {
                    .post-registration-input {
                        .wrapper-for-input {
                            .help-post-registration-button {
                                left: 260px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 414px) and (max-height: 736px) {
    .login {
        display: none;
    }
    .login-mobile {
        display: block;
        max-width: 414px;
        height: 736px;
        background: url('./images/bg-6plus.jpg') no-repeat;
    }
    .registration {
        display: none;
    }
    .registration-mobile {
        display: block;
        max-width: 414px;
        height: 736px;
        background: url('./images/bg-6plus.jpg') no-repeat;
        .registration-input {
          .wrapper-for-input {
            .help-registration-button {
              left: 300px;
            }
          }
        }
    }
    .spinner_wrap {
        .loader-svg {
            left: 0;
            & + p {
                margin-top: 50px;
                left: calc(50% - 50px);
            }
            p {
                right: calc(50% - 100px);
            }
        }
    }
    @include input-help-animation-mobile(95px);
}

@media screen and (max-width: 375px) and (max-height: 670px) {
    .login {
        display: none;
    }
    .login-mobile {
        display: block;
        max-width: 375px;
        height: 670px;
        background: url('./images/background.png') no-repeat;
    }
    .registration {
        display: none;
    }
    .registration-mobile {
        display: block;
        max-width: 375px;
        height: 670px;
        background: url('./images/background.png') no-repeat;
        .registration-input {
          .wrapper-for-input {
            .help-registration-button {
              left: 280px;
            }
          }
        }
    }
    .spinner_wrap {
        .loader-svg {
            left: 0;
            & + p {
              margin-top: 70px;
              right: calc(60% - 100px);
            }
        }
    }
    @include input-help-animation-mobile(80px);
}

@media (max-width: 360px) {
    @include input-help-animation-mobile(65px);
}

@media (max-width: 340px) {
    @include input-help-animation-mobile(65px);
}

@media (max-width: 330px) {
    .login-mobile {
        .login-input {
            .wrapper-for-all-inputs {
                .wrapper-for-input {
                    .help-login-button {
                        left: 230px;
                    }
                }
            }
        }
    }
    .registration-mobile {
        .registration-input {
            .wrapper-for-input {
                .help-registration-button {
                    left: 230px;
                }
            }
        }
        .post-registration-input {
            .wrapper-for-input {
                .help-post-registration-button {
                    left: 230px;
                }
            }
        }
    }
}

@media (max-width: 320px) {
    @include input-help-animation-mobile(65px);
}
@media (max-width: 315px) {
  .page {
      .sidebar_view {
          .sidebar_wrapper {
              .wrapper, .wrapper-scroll-not {
                  .all_closest {
                      .closest {
                          .sorry {
                              .sorry-warning {
                                  margin-top: 16px;
                              }
                          }
                      }
                  }
              }
          }
      }
  }
}

@media (max-width: 300px) {
    // popup
    .page {
        .pop-up-question {
            height: 440px;
            .pop-up-question-button-yes, .pop-up-question-button-no {
                width: calc(100% - 50px);
                font-size: 18px;
            }

        }
        .pop-up-feedback {
            .pop-up-button {
                width: 100%;
                max-width: 150px;
            }
            .wrapper-for-input {
                .input-help-animation-pop-up-in {
                    left: 20px;
                }
                .input-help-animation-pop-up-out {
                    left: 20px;
                }
            }
        }
    }
    @include input-help-animation-mobile(65px);
}

@media (max-height: 655px) {
    // popup
    .page {
        .pop-up-share {
            margin-bottom: 50px;
            top: 50px;
        }
    }
}


@media (max-height: 540px) {
    // popup
    .page {
        .pop-up-feedback {
            margin-bottom: 50px;
            top: 50px;
        }
    }
}
