$background-all: #303F9F;
$background-button-login-color: #006B9C;
$background-button-registration-color: #42b863;
$background-button-popup-feedback: #A6BE66;
$background-button-popup-link-share: #4586B4;
$background-popup-scan-wrapper: #EBF7FF;
$background-popup-line: #E1E1E1;
$background-popup: #FFFFFF;
$background-stop-title: #4C5863;
$background-page-tab: #A3A3A3;
$background-page-tab-active: #0288D1;
$background-down: #2A4C78;
$background-input-wrong: #E4E7EC;
$background-input-help: #F36D4F;
$background-input-help-button: #F36D4F;
$color-login-registration-link: #585858;
$color-text: #FFFFFF;
$color-text-dark: #000000;
$color-text-input: #4A7698;
$color-text-required: #5B83A2;
$color-text-wrapper: #4A7698;
$color-text-stop-title: #E0F1FF;
$color-button-feedback-popup: #FEFEFE;
$color-text-popup: #444;
$color-text-popup-rating-description: #8D8D8D;
$color-text-popup-rating-text: #DBDBDB;
$color-text-popup-link: #5595C4;
$color-title-under-popup: #8D8D8D;
$color-stop-text: #FFFFFF;
$color-text-input-wrong: #F1816B;
$border-stop-title: 5px solid #5381AB;
$border-page-tab: 1px solid #FFFFFF;
$border-down: 3px solid #4D6484;
$border-list-stops: 3px solid #FF4500;
$border-color-down: #FFFFFF;
$border-input-wrong: #F36D4F;
$center: #00FFFF;
$footer-color: #3D4D59;
$linear-page-percent: 49%;
$shadow: 49%;
$login-font-family: 'Avenir Next';
$login-font-family-button: 'Avenir Next';
