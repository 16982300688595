.map {
    color: $color-text;
    ul  {
        list-style-type: none;
        padding: 0px;
        .background {
            background-color: white;
            .page-tab-first {
                @include map-tab(50px, 30px, 15px, 5px, 10px);
                // float: left;
            }
            .page-tab-first-active {
                @include map-tab(50px, 32px, 15px, 9px, 10px);
                // float: left;
                margin-top: -2px;
                border-bottom: 3px solid #303F9F;
            }
            .page-tab-second {
                @include map-tab(50px, 30px, 15px, 5px, 10px);
                // float: left;
            }
            .page-tab-second-active {
                @include map-tab(50px, 32px, 15px, 0, 10px);
                // float: left;
                padding-left: 6px;
                margin-top: -2px;
                border-bottom: 3px solid #303F9F;
            }
            .page-tab-third {
                @include map-tab(50px, 30px, 15px, 5px, 10px);
                // float: left;
            }
            .page-tab-third-active {
                @include map-tab(50px, 32px, 15px, 0, 10px);
                // float: left;
                padding-left: 6px;
                margin-top: -2px;
                border-bottom: 3px solid #303F9F;
            }
        }
    }
    .angular-google-map {
        @include image(300px, 400px);
        .angular-google-map-container {
            @include image(96%, 400px);
            position: absolute;
        }
    }
}
